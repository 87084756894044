import * as React from 'react'
import { createUseStyles } from 'react-jss'
import { UserOutlined } from '@ant-design/icons'
import {
    Avatar,
    Button,
    Space,
    Typography
} from 'antd'

const useStyles = createUseStyles({
    container: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row'
    },
    image: {
        marginRight: 16,
        backgroundColor: '#ccc'
    }
})

const Employee = props => {
    const {
        container,
        image
    } = useStyles()

    const {
        name,
        avatar,
        onClickEdit,
        onClickDelete
    } = props

    return (
        <div className={container}>
            {
                avatar
                    ?
                    <Avatar
                        className={image}
                        size={40}
                        src={`${process.env.REACT_APP_API_URL}/img/${avatar}?w=64&h=64&fit=crop`}
                    />
                    :
                    <Avatar
                        className={image}
                        size={40}
                        icon={
                            <UserOutlined />
                        }
                    />
            }
            <Typography.Text strong>
                {name}
            </Typography.Text>
            <div style={{ flex: 1 }}></div>
            <Space>
                <Button
                    onClick={onClickEdit}
                    type="primary"
                    ghost
                >
                    Өөрчлөх
                </Button>
                <Button
                    onClick={onClickDelete}
                    type="primary"
                >
                    Устгах
                </Button>
            </Space>
        </div>
    )
}

export default Employee
