import * as React from 'react'
import {
    useParams,
    generatePath
} from 'react-router-dom'
import { createUseStyles } from 'react-jss'
import {
    Get,
    Post
} from 'react-axios'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import {
    Button,
    Divider,
    Empty,
    Form,
    Input,
    message,
    Modal,
    Spin,
    Tabs,
    Typography
} from 'antd'
import { AppSettings } from './../layouts'
import {
    Admin,
    AdminInvitation
} from './../components'
import { API } from './../plugins/axios'

const useStyles = createUseStyles({
    title: {
        marginBottom: 16
    },
    subtitle: {
        marginTop: 2,
        marginBottom: 16
    },
    spin: {
        textAlign: 'center'
    }
})

const defaultErrors = {
    email: null
}

const Admins = props => {
    const { slug: id } = useParams()
    const {
        title,
        subtitle,
        spin
    } = useStyles()

    const [
        errors,
        setErrors
    ] = React.useState(defaultErrors)
    const [
        email,
        setEmail
    ] = React.useState('')
    const [
        admins,
        setAdmins
    ] = React.useState([])
    const [
        adminInvitations,
        setAdminInvitations
    ] = React.useState([])

    const validateStatus = prop => {
        return errors[prop] === null
            ? ''
            : 'error'
    }

    const invite = makeRequest => {
        setErrors(defaultErrors)
        makeRequest()
    }

    const destroy = email => {
        Modal.confirm({
            title: 'Устгах',
            icon: <ExclamationCircleOutlined />,
            content: 'Та энэ админыг устгахыг зөвшөөрч байна уу?',
            okText: 'Тийм',
            cancelText: 'Үгүй',
            onOk: async () => {
                try {
                    await API.post(generatePath('api/detach/admin/:id', { id }), { email })
                    setAdmins(admins.filter(admin => admin.id !== id))
                } catch (err) {
                    //
                }
            }
        })
    }

    const onError = err => {
        if (err.response && err.response.status === 422) {
            setErrors({
                ...defaultErrors,
                ...err.response.data.errors
            })
        }
    }

    const onSuccess = () => {
        setEmail('')
        message.success('Таны оруулсан и-мэйл хаягтай хэрэглэгч рүү урилга илгээлээ')
    }

    return (
        <AppSettings>
            <Typography.Title level={4}>
                Админ
            </Typography.Title>
            <Divider />
            <Post
                url={generatePath('api/attach/admin/:id', { id })}
                isReady={false}
                data={{
                    email
                }}
                onError={onError}
                onSuccess={onSuccess}
            >
                {(error, response, loading, makeRequest) => (
                    <Form onFinish={() => invite(makeRequest)}>
                        <Typography.Title level={5}>
                            Админ урих
                        </Typography.Title>
                        <div className={subtitle}>
                            <Typography.Text type="secondary">
                                Та шинэ хэрэглэгч байгууллагынхаа админаар урих боломжтой
                            </Typography.Text>
                        </div>
                        <Form.Item
                            validateStatus={validateStatus('email')}
                            help={errors.email}
                        >
                            <Input
                                size="large"
                                placeholder="И-мэйл хаяг"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                            />
                        </Form.Item>
                        <Button
                            loading={loading}
                            type="primary"
                            htmlType="submit"
                            ghost
                        >
                            Урих
                        </Button>
                    </Form>
                )}
            </Post>
            <Divider />
            <div className={title}>
                <Typography.Title level={5}>
                    Админууд
                </Typography.Title>
            </div>
            <Tabs defaultActiveKey="1">
                <Tabs.TabPane
                    tab="Идэвхитэй"
                    key="1"
                >
                    <Get
                        url={generatePath('api/organizations/:id/admins', { id })}
                        onSuccess={({ data }) => setAdmins(data)}
                        params={{
                            perPage: 0
                        }}
                    >
                        {(error, response, loading) => (
                            <div>
                                <div className={spin}>
                                    <Spin spinning={loading} />
                                </div>
                                {admins.map(({ id, name, email }) => (
                                    <Admin
                                        key={id}
                                        name={name}
                                        email={email}
                                        onClickDelete={() => destroy(email)}
                                    />
                                ))}
                            </div>
                        )}
                    </Get>
                </Tabs.TabPane>
                <Tabs.TabPane
                    tab="Хүлээгдэж буй"
                    key="2"
                >
                    <Get
                        url={generatePath('api/organizations/:id/admin/invitations', { id })}
                        onSuccess={({ data }) => setAdminInvitations(data)}
                        params={{
                            perPage: 0
                        }}
                    >
                        {(error, response, loading) => {
                            if (loading) {
                                return (
                                    <div className={spin}>
                                        <Spin />
                                    </div>
                                )
                            }

                            if (!adminInvitations.length) {
                                return (
                                    <Empty
                                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        description="Хүлээгдэж буй админ байхгүй байна"
                                    />
                                )
                            }

                            return (
                                <div>
                                    {adminInvitations.map(({ id, email }) => (
                                        <AdminInvitation
                                            key={id}
                                            email={email}
                                        />
                                    ))}
                                </div>
                            )
                        }}
                    </Get>
                </Tabs.TabPane>
            </Tabs>
        </AppSettings>
    )
}

export default Admins