import React from 'react'
import { AxiosProvider } from 'react-axios'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { API } from './plugins/axios'
import {
    store,
    persistor
} from './redux/store'
import Router from './Router'

export default () => (
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <AxiosProvider instance={API}>
                <Router />
            </AxiosProvider>
        </PersistGate>
    </Provider>
)