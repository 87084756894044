import * as React from 'react'
import { Link } from 'react-router-dom'
import { Result } from 'antd'

const NoMatch = () => {
    return (
        <div>
            <Result
                title="404"
                subTitle="Sorry, the page you were looking for does not exist."
                status="404"
                extra={
                    <Link
                        className="ant-btn ant-btn-primary"
                        to="/"
                    >
                        Back Home
                    </Link>
                }
            />
        </div>
    )
}

export default NoMatch