import * as React from 'react'
import classNames from 'classnames'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
    container: {
        paddingTop: 16,
        paddingBottom: 16
    }
})

const List = props => {
    const {
        container
    } = useStyles()

    const {
        className,
        children
    } = props

    return (
        <div className={classNames(container, className)}>
            {children}
        </div>
    )
}

export default List
