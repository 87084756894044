import * as React from 'react'
import moment from 'moment'
import {
    Link,
    useParams,
    generatePath
} from 'react-router-dom'
import { createUseStyles } from 'react-jss'
import {
    Button,
    Divider,
    Typography
} from 'antd'

const useStyles = createUseStyles({
    card: {
        padding: 24
    },
    divider: {
        marginTop: 8,
        marginBottom: 8
    },
    button: {
        marginTop: 12
    }
})

const Appointment = props => {
    const { slug } = useParams()
    const {
        card,
        divider,
        button
    } = useStyles()
    const {
        id,
        name,
        surname,
        phone,
        from,
        to,
        serviceName
    } = props

    return (
        <div className={card}>
            <div>
                <Typography.Text strong>
                    {moment(from, 'HH:mm:ss').format('HH:mm')} - {moment(to, 'HH:mm:ss').format('HH:mm')}
                </Typography.Text>
            </div>
            <Divider className={divider} />
            <div>
                <Typography.Text strong>
                    {surname} {name} {!!phone && `(${phone})`}
                </Typography.Text>
            </div>
            <div>
                <Typography.Text type="secondary">
                    {serviceName}
                </Typography.Text>
            </div>
            <Link
                className={button}
                component={Button}
                to={generatePath('/org/:slug/appointments/:id', { slug, id })}
                type="primary"
                ghost
            >
                Дэлгэрэнгүй
            </Link>
        </div>
    )
}

export default Appointment