import * as React from 'react'
import { useParams } from 'react-router-dom'
import { generatePath } from 'react-router-dom'
import { createUseStyles } from 'react-jss'
import { Get } from 'react-axios'
import {
    Alert,
    Col,
    Divider,
    Form,
    Row,
    Select,
    Typography
} from 'antd'
import { AppSettings } from './../layouts'
import {
    SetSchedule,
    SetScheduleDate
} from './../components'
import { Spinner } from './../components/common'

const useStyles = createUseStyles({
    subtitle: {
        marginTop: 2,
        marginBottom: 16
    }
})

const ServicesSetSchedule = props => {
    const { id } = useParams()
    const { subtitle } = useStyles()

    const [
        name,
        setName
    ] = React.useState('')
    const [
        appointable,
        setAppointable
    ] = React.useState(false)
    const [
        employees,
        setEmployees
    ] = React.useState([])
    const [
        ruleableId,
        setruleableId
    ] = React.useState(null)

    const setData = data => {
        const {
            name,
            appointable,
            employees
        } = data

        setName(name)
        setAppointable(appointable)
        setEmployees(employees)
    }

    return (
        <AppSettings>
            <Get
                url={generatePath('api/services/:id', { id })}
                onSuccess={({ data }) => setData(data)}
                params={{
                    with: 'employees'
                }}
            >
                {(error, response, loading) => {
                    if (loading) {
                        return (
                            <Spinner visible={true} />
                        )
                    }

                    return (
                        <div>
                            <Typography.Title level={4}>
                                {name}
                            </Typography.Title>
                            <div className={subtitle}>
                                <Typography.Text type="secondary">
                                    Тус үйлчилгээний захиалах боломжтой цагийн хуваарийг тохируулаарай
                                </Typography.Text>
                            </div>
                            <Divider />
                            {appointable
                                ?
                                <Row gutter={16}>
                                    <Col xs={14}>
                                        <SetSchedule
                                            ruleableId={id}
                                            ruleableType="services"
                                        />
                                    </Col>
                                    <Col xs={10}>
                                        <SetScheduleDate
                                            ruleableId={id}
                                            ruleableType="services"
                                        />
                                    </Col>
                                </Row>
                                :
                                <div>
                                    {employees.length === 0
                                        ?
                                        <Alert
                                            type="warning"
                                            message="Та цаг тохируулахын тулд эхлээд тус үйлчилгээнд ажилчин нэмэх эсвэл ажилчин хамаарахгүй тохиргоог идэвхжүүлэх шаардлагатай"
                                            showIcon
                                        />
                                        :
                                        <div>
                                            <Row gutter={16}>
                                                <Col xs={14}>
                                                    <Form.Item>
                                                        <Select
                                                            size="large"
                                                            placeholder="Ажилчин сонгох"
                                                            value={ruleableId}
                                                            onChange={setruleableId}
                                                            allowClear
                                                        >
                                                            {employees.map(({ id, name, pivot }) => (
                                                                <Select.Option
                                                                    key={id}
                                                                    value={pivot.id}
                                                                >
                                                                    {name}
                                                                </Select.Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            {!!ruleableId && (
                                                <div key={ruleableId}>
                                                    <Row gutter={16}>
                                                        <Col xs={14}>
                                                            <SetSchedule
                                                                ruleableId={ruleableId}
                                                                ruleableType="employee-services"
                                                            />
                                                        </Col>
                                                        <Col xs={10}>
                                                            <SetScheduleDate
                                                                ruleableId={ruleableId}
                                                                ruleableType="employee-services"
                                                            />
                                                        </Col>
                                                    </Row>
                                                </div>
                                            )}
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    )
                }}
            </Get>
        </AppSettings>
    )
}

export default ServicesSetSchedule