import * as React from 'react'
import { createUseStyles } from 'react-jss'
import { Spin } from 'antd'

const useStyles = createUseStyles({
    wrapper: {
        position: 'fixed',
        width: '100%',
        top: 0,
        left: 0,
        bottom: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.65)',
        zIndex: 4
    },
    spin: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        margin: '-10px'
    }
})

const Spinner = props => {
    const {
        wrapper,
        spin
    } = useStyles()

    return (
        <div>
            {props.visible &&
                <div className={wrapper}>
                    <Spin
                        className={spin}
                        spinning={true}
                    />
                </div>
            }
        </div>
    )
}

export default Spinner