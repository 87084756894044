import * as React from 'react'
import { connect } from 'react-redux'
import { createUseStyles } from 'react-jss'
import { withRouter } from 'react-router'
import { Post } from 'react-axios'
import { MailOutlined } from '@ant-design/icons'
import {
    Button,
    Card,
    Col,
    Form,
    Input,
    PageHeader,
    Row
} from 'antd'
import { App } from './../layouts'

const useStyles = createUseStyles({
    illustration: {
        margin: '24px auto 32px',
        display: 'block'
    },
    action: {
        float: 'right'
    }
})

const defaultErrors = {
    email: null
}

const ChangeEmailAddress = props => {
    const {
        illustration,
        action
    } = useStyles()

    const [
        errors,
        setErrors
    ] = React.useState(defaultErrors)
    const [
        email,
        setEmail
    ] = React.useState(props.user.email)

    const validateStatus = prop => {
        return errors[prop] === null
            ? ''
            : 'error'
    }

    const update = makeRequest => {
        setErrors(defaultErrors)
        makeRequest()
    }

    const onError = err => {
        if (err.response && err.response.status === 422) {
            setErrors({
                ...defaultErrors,
                ...err.response.data.errors
            })
        }
    }

    const onSuccess = () => props.history.push(`/change/email?email=${email}`)

    return (
        <App>
            <Row
                gutter={16}
                justify="center"
            >
                <Col
                    xs={24}
                    sm={20}
                    md={16}
                    lg={12}
                >
                    <PageHeader
                        onBack={() => props.history.goBack()}
                        title="И-мэйл хаяг солих"
                    />
                    <Card>
                        <Post
                            url="api/admin/change/email/request"
                            isReady={false}
                            data={{
                                email
                            }}
                            onError={onError}
                            onSuccess={onSuccess}
                        >
                            {(error, response, loading, makeRequest) => (
                                <Form onFinish={() => update(makeRequest)}>
                                    <img
                                        height="100"
                                        className={illustration}
                                        alt="card"
                                        src="/static/images/email.svg"
                                    />
                                    <Form.Item
                                        validateStatus={validateStatus('email')}
                                        help={errors.email}
                                    >
                                        <Input
                                            size="large"
                                            placeholder="И-мэйл хаяг"
                                            value={email}
                                            onChange={e => setEmail(e.target.value)}
                                            prefix={
                                                <MailOutlined />
                                            }
                                        />
                                    </Form.Item>
                                    <div className={action}>
                                        <Button
                                            loading={loading}
                                            type="primary"
                                            htmlType="submit"
                                            ghost
                                        >
                                            Үргэлжлүүлэх
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </Post>
                    </Card>
                </Col>
            </Row>
        </App>
    )
}

const mapStateToProps = state => ({
    user: state.auth.user
})

export default withRouter(connect(
    mapStateToProps,
    null
)(ChangeEmailAddress))
