import * as React from 'react'
import { connect } from 'react-redux'
import { createUseStyles } from 'react-jss'
import { withRouter } from 'react-router'
import {
    LockOutlined,
    MailOutlined,
    UserOutlined
} from '@ant-design/icons'
import {
    Card,
    Col,
    Divider,
    Row
} from 'antd'
import { App } from './../layouts'
import { setUser } from './../redux/auth/actions'
import {
    List,
    ListItem
} from './../components/common'
import { Avatar } from './../components'

const useStyles = createUseStyles({
    card: {
        textAlign: 'center'
    },
    divider: {
        margin: 0
    },
    list: {
        marginTop: 24
    }
})

const User = props => {
    const {
        user,
        accessToken
    } = props
    const {
        card,
        divider,
        list
    } = useStyles()

    return (
        <App>
            <Row
                gutter={16}
                justify="center"
            >
                <Col
                    xs={24}
                    sm={20}
                    md={16}
                    lg={12}
                >
                    <Card className={card}>
                        <Avatar
                            url="/api/admin/user/profile/image"
                            src={`${process.env.REACT_APP_API_URL}/img/${user.profileImage}?api_token=${accessToken}&w=200&h=200&fit=crop`}
                            size={96}
                            onSuccess={({ user }) => props.setUser(user)}
                        />
                        <List className={list}>
                            <ListItem
                                title="Нэр"
                                description={user.name}
                                actionText="Өөрчлөх"
                                onClickAction={() => props.history.push('/change/name')}
                                icon={
                                    <UserOutlined />
                                }
                            />
                            <Divider className={divider} />
                            <ListItem
                                title="И-мэйл хаяг"
                                description={user.email}
                                actionText="Өөрчлөх"
                                onClickAction={() => props.history.push('/change/email/request')}
                                icon={
                                    <MailOutlined />
                                }
                            />
                            <Divider className={divider} />
                            <ListItem
                                title="Нууц үг солих"
                                actionText="Өөрчлөх"
                                onClickAction={() => props.history.push('/change/password')}
                                icon={
                                    <LockOutlined />
                                }
                            />
                        </List>
                    </Card>
                </Col>
            </Row>
        </App>
    )
}

const mapStateToProps = state => {
    const {
        user,
        accessToken
    } = state.auth

    return {
        user,
        accessToken
    }
}

const mapDispatchToProps = {
    setUser
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(User))
