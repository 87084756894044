import * as React from 'react'
import { createUseStyles } from 'react-jss'
import { Get } from 'react-axios'
import { SearchOutlined } from '@ant-design/icons'
import {
    Col,
    Input,
    Row,
    Table,
    Typography
} from 'antd'
import { debounce } from './../../utils/helpers'

const useStyles = createUseStyles({
    header: {
        marginBottom: 16
    }
})

const DataTable = props => {
    const { header } = useStyles()
    const {
        children,
        title,
        url
    } = props

    const [
        data,
        setData
    ] = React.useState([])
    const [
        search,
        setSearch
    ] = React.useState('')
    const [
        sortBy,
        setSortBy
    ] = React.useState('created_at')
    const [
        sortOrder,
        setSortOrder
    ] = React.useState('desc')
    const [
        page,
        setPage
    ] = React.useState(1)
    const [
        perPage
    ] = React.useState(20)
    const [
        total,
        setTotal
    ] = React.useState(0)

    const onSuccess = ({ data, total, currentPage }) => {
        setData(data)
        setPage(currentPage)
        setTotal(total)
    }

    const onChangeSearch = e => {
        debounce(() => {
            setSearch(e.target.value)
        })
    }

    const onChange = ({ current }) => setPage(current)

    return (
        <div>
            <Get
                url={url}
                onSuccess={onSuccess}
                params={{
                    page,
                    perPage,
                    search,
                    sortBy,
                    sortOrder
                }}
            >
                {(error, response, loading) => (
                    <div>
                        <div className={header}>
                            <Row
                                gutter={16}
                                align="middle"
                                justify="space-between"
                            >
                                <Col>
                                    <Typography.Title level={4}>
                                        {title}
                                    </Typography.Title>
                                </Col>
                                <Col
                                    xs={12}
                                    md={8}
                                    xl={6}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Хайх"
                                        onChange={onChangeSearch}
                                        prefix={
                                            <SearchOutlined />
                                        }
                                    />
                                </Col>
                            </Row>
                        </div>
                        <Table
                            rowKey="id"
                            scroll={{ x: true }}
                            dataSource={data}
                            loading={loading}
                            onChange={onChange}
                            pagination={{
                                total,
                                page,
                                pageSize: perPage
                            }}
                        >
                            {children}
                        </Table>
                    </div>
                )}
            </Get>
        </div>
    )
}

DataTable.Column = Table.Column
DataTable.ColumnGroup = Table.ColumnGroup

export default DataTable