import * as React from 'react'
import classNames from 'classnames'
import { createUseStyles } from 'react-jss'
import { Link } from 'react-router-dom'
import { Typography } from 'antd'
import { fromNow } from './../utils/helpers'

const useStyles = createUseStyles({
    link: {
        display: 'block',
        padding: '0 24px'
    },
    unread: {
        backgroundColor: '#f6f6f6'
    },
    container: {
        width: 360,
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 16,
        paddingBottom: 16
    }
})

const Notification = props => {
    const {
        link,
        unread,
        container
    } = useStyles()
    const {
        type,
        data,
        readAt,
        createdAt
    } = props

    const className = classNames('card-link', link, {
        [unread]: !readAt
    })

    const text = () => {
        switch (type) {
            case 'App\\Notifications\\NewAppointment':
                return `${data.appointment.appointable.phone} дугаартай хэрэглэгч цаг захиаллаа`
        }
    }

    const to = () => {
        switch (type) {
            case 'App\\Notifications\\NewAppointment':
                return `/org/${data.appointment.service.organization.id}/appointments/${data.appointment.id}`
        }
    }

    return (
        <Link
            className={className}
            to={to()}
        >
            <div className={container}>
                <Typography.Text>
                    {text()}
                </Typography.Text>
                <Typography.Text type="secondary">
                    {fromNow(createdAt)}
                </Typography.Text>
            </div>
        </Link>
    )
}

export default Notification
