import * as React from 'react'
import moment from 'moment'
import { uuid } from 'uuidv4'
import { generatePath } from 'react-router-dom'
import { createUseStyles } from 'react-jss'
import {
    Get,
    Post
} from 'react-axios'
import {
    ExclamationCircleOutlined,
    MinusCircleOutlined,
    PlusCircleOutlined
} from '@ant-design/icons'
import {
    Button,
    Card,
    Col,
    DatePicker,
    Divider,
    Empty,
    Form,
    message,
    Modal,
    Row,
    Spin,
    TimePicker,
    Typography
} from 'antd'
import { ScheduleDate } from './../components'
import { API } from './../plugins/axios'

const useStyles = createUseStyles({
    iconButton: {
        cursor: 'pointer',
        position: 'relative',
        top: 6,
        fontSize: 24,
        color: 'rgba(0, 0, 0, 0.45)'
    },
    buttonContainer: {
        textAlign: 'center',
        marginBottom: 24
    },
    spin: {
        textAlign: 'center',
        paddingTop: 24,
        paddingBottom: 24
    }
})

const defaultErrors = {
    //
}

const SetScheduleDate = props => {
    const {
        ruleableId,
        ruleableType
    } = props
    const {
        iconButton,
        buttonContainer,
        spin
    } = useStyles()

    const [
        key,
        setKey
    ] = React.useState(uuid())
    const [
        errors,
        setErrors
    ] = React.useState(defaultErrors)
    const [
        visible,
        setVisible
    ] = React.useState(false)
    const [
        rules,
        setRules
    ] = React.useState([])
    const [
        date,
        setDate
    ] = React.useState('')
    const [
        intervals,
        setIntervals
    ] = React.useState([])

    const setNewRule = () => {
        setDate(moment().format('YYYY-MM-DD'))
        setIntervals([{
            from: '',
            to: ''
        }])
        setVisible(true)
    }

    const validateStatus = index => {
        return errors[`intervals.${index}.from`] || errors[`intervals.${index}.to`]
            ? 'error'
            : ''
    }

    const getInterval = index => {
        if (
            !intervals[index].from ||
            !intervals[index].to
        ) {
            return [
                null,
                null
            ]
        }

        return [
            moment(intervals[index].from, 'HH:mm:ss'),
            moment(intervals[index].to, 'HH:mm:ss')
        ]
    }

    const onChange = (index, time) => {
        const [
            from,
            to
        ] = time

        const array = [...intervals]
        array[index] = {
            from,
            to
        }

        setIntervals(array)
    }

    const add = () => {
        setIntervals([
            ...intervals,
            {
                from: '',
                to: ''
            }
        ])
    }

    const destroy = value => {
        setIntervals(intervals.filter((_, index) => index !== value))
    }

    const save = makeRequest => {
        setErrors(defaultErrors)
        makeRequest()
    }

    const destroyRule = id => {
        Modal.confirm({
            title: 'Устгах',
            icon: <ExclamationCircleOutlined />,
            content: 'Та энэ тохиргоог устгахыг зөвшөөрч байна уу?',
            okText: 'Тийм',
            cancelText: 'Үгүй',
            onOk: async () => {
                try {
                    await API.delete(generatePath('api/rules/:id', { id }))
                    setKey(uuid())
                } catch (err) {
                    //
                }
            }
        })
    }

    const onError = err => {
        if (err.response && err.response.status === 422) {
            setErrors({
                ...defaultErrors,
                ...err.response.data.errors
            })
        }
    }

    const onSuccess = () => {
        message.success('Цагийн тохиргоо амжилттай хадгаллаа')
        setKey(uuid())
        setVisible(false)
    }

    return (
        <div>
            <Card>
                <Typography.Text>
                    Өдрөөр цаг тохируулах
                </Typography.Text>
                <Divider />
                <div className={buttonContainer}>
                    <Button
                        onClick={setNewRule}
                        type="primary"
                        block
                        ghost
                    >
                        Шинэ
                    </Button>
                </div>
                <div key={key}>
                    <Get
                        url={generatePath('api/:ruleableType/:ruleableId/rules', { ruleableType, ruleableId })}
                        onSuccess={({ data }) => setRules(data)}
                        params={{
                            type: 'date'
                        }}
                    >
                        {(error, response, loading) => {
                            if (loading) {
                                return (
                                    <div className={spin}>
                                        <Spin />
                                    </div>
                                )
                            }

                            if (rules.length === 0) {
                                return (
                                    <Empty
                                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        description="Цаг тохируулаагүй байна"
                                    />
                                )
                            }

                            return (
                                <div>
                                    {rules.map(({ id, date, intervals }, index) => (
                                        <ScheduleDate
                                            key={index}
                                            date={date}
                                            intervals={intervals}
                                            onClickDelete={() => destroyRule(id)}
                                        />
                                    ))}
                                </div>
                            )
                        }}
                    </Get>
                </div>
            </Card>
            <Modal
                title="Цаг тохируулах"
                onCancel={() => setVisible(false)}
                footer={null}
                visible={visible}
                closable={false}
            >
                <Post
                    url="api/rules/intervals"
                    isReady={false}
                    data={{
                        intervals,
                        date,
                        ruleableId,
                        ruleableType
                    }}
                    onError={onError}
                    onSuccess={onSuccess}
                >
                    {(error, response, loading, makeRequest) => (
                        <Form onFinish={() => save(makeRequest)}>
                            <Row gutter={16}>
                                <Col
                                    xs={16}
                                    sm={20}
                                >
                                    <DatePicker
                                        placeholder="Огноо"
                                        size="large"
                                        allowClear={false}
                                        value={moment(date)}
                                        onChange={(time, timeString) => setDate(timeString)}
                                    />
                                </Col>
                            </Row>
                            <Divider />
                            {intervals.length === 0 && (
                                <div>
                                    <Empty
                                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        description="Цагийн тохиргоо байхгүй"
                                    />
                                    <div className={buttonContainer}>
                                        <Button
                                            type="primary"
                                            onClick={add}
                                        >
                                            Цаг тохируулах
                                        </Button>
                                    </div>
                                </div>
                            )}
                            {intervals.map(({ from, to }, index) => (
                                <Row
                                    key={index}
                                    gutter={16}
                                >
                                    <Col
                                        xs={16}
                                        sm={20}
                                    >
                                        <Form.Item validateStatus={validateStatus(index)}>
                                            <TimePicker.RangePicker
                                                value={getInterval(index)}
                                                onChange={(time, timeString) => onChange(index, timeString)}
                                                format="HH:mm"
                                                size="large"
                                                placeholder={[
                                                    'Эхлэх', 'Дуусах'
                                                ]}
                                                allowClear
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col
                                        xs={4}
                                        sm={2}
                                    >
                                        <MinusCircleOutlined
                                            className={iconButton}
                                            onClick={() => destroy(index)}
                                        />
                                    </Col>
                                    <Col
                                        xs={4}
                                        sm={2}
                                    >
                                        {index === 0 &&
                                            <PlusCircleOutlined
                                                className={iconButton}
                                                onClick={add}
                                            />
                                        }
                                    </Col>
                                </Row>
                            ))}
                            <Button
                                loading={loading}
                                type="primary"
                                htmlType="submit"
                                ghost
                            >
                                Хадгалах
                            </Button>
                        </Form>
                    )}
                </Post>
            </Modal>
        </div>
    )
}

export default SetScheduleDate