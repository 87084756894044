import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import { store } from './../redux/store'

window.Pusher = Pusher

export default () => {
    const state = store.getState()
    const {
        REACT_APP_API_URL,
        REACT_APP_PUSHER_APP_KEY,
        REACT_APP_PUSHER_APP_CLUSTER
    } = process.env

    return new Echo({
        broadcaster: 'pusher',
        key: REACT_APP_PUSHER_APP_KEY,
        authEndpoint: `${REACT_APP_API_URL}/broadcasting/auth`,
        cluster: REACT_APP_PUSHER_APP_CLUSTER,
        auth: {
            headers: {
                Authorization: `Bearer ${state.auth.accessToken}`,
            }
        }
    })
}
