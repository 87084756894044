import * as React from 'react'
import { connect } from 'react-redux'
import {
    Route,
    Redirect
} from 'react-router-dom'

const GuestRoute = props => {
    const {
        user,
        ...other
    } = props

    return user
        ? <Redirect to="/" />
        : <Route {...other} />
}

const mapStateToProps = state => ({
    user: state.auth.user
})

export default connect(mapStateToProps)(GuestRoute)
