import * as React from 'react'
import moment from 'moment'
import {
    Link,
    useParams,
    generatePath
} from 'react-router-dom'
import { App } from './../layouts'
import { DataTable } from './../components/common'

const Appointments = props => {
    const { slug } = useParams()

    return (
        <App>
            <DataTable
                title="Захиалга"
                url={generatePath('api/organizations/:id/appointments', { id: slug })}
            >
                <DataTable.Column
                    title="Нэр"
                    key="name"
                    dataIndex="appointable"
                    render={({ name }) => name}
                />
                <DataTable.Column
                    title="Овог"
                    key="surname"
                    dataIndex="appointable"
                    render={({ surname }) => surname}
                />
                <DataTable.Column
                    title="Утасны дугаар"
                    key="phone"
                    dataIndex="appointable"
                    render={({ phone }) => phone}
                />
                <DataTable.Column
                    title="Үйлчилгээ"
                    dataIndex="service"
                    render={({ name }) => name}
                />
                <DataTable.Column
                    title="Огноо"
                    dataIndex="date"
                    sorter
                />
                <DataTable.Column
                    title="Цаг"
                    dataIndex="from"
                    render={from => moment(from, 'HH:mm:ss').format('HH:mm')}
                    sorter
                />
                <DataTable.Column
                    title="Үүсгэсэн огноо"
                    dataIndex="createdAt"
                    sorter
                />
                <DataTable.Column
                    dataIndex="id"
                    render={id => (
                        <Link to={generatePath('/org/:slug/appointments/:id', { slug, id })}>
                            Дэлгэрэнгүй
                        </Link>
                    )}
                />
            </DataTable>
        </App >
    )
}

export default Appointments