import 'moment/locale/mn'
import _ from 'lodash'
import moment from 'moment'

moment.locale('mn')

/**
 * Moment fromNow function
 */
const fromNow = date => moment(date).fromNow()

/**
 * Moment format
 */
const formatHumanize = date => moment(date).format('dddd, YYYY оны MMMын D')

/**
 * Lodash debounce function
 */
const debounce = _.debounce(callFunc => {
    callFunc()
}, 500)

/**
 * Return weekday name
 */
const weekdayName = weekday => {
    if (weekday === 'monday') {
        return 'Даваа'
    }

    if (weekday === 'tuesday') {
        return 'Мягмар'
    }

    if (weekday === 'wednesday') {
        return 'Лхагва'
    }

    if (weekday === 'thursday') {
        return 'Пүрэв'
    }

    if (weekday === 'friday') {
        return 'Баасан'
    }

    if (weekday === 'saturday') {
        return 'Бямба'
    }

    if (weekday === 'sunday') {
        return 'Ням'
    }

    return ''
}

export {
    fromNow,
    formatHumanize,
    debounce,
    weekdayName
}