import * as React from 'react'
import moment from 'moment'
import { uuid } from 'uuidv4'
import { generatePath } from 'react-router-dom'
import { createUseStyles } from 'react-jss'
import {
    Get,
    Post
} from 'react-axios'
import {
    MinusCircleOutlined,
    PlusCircleOutlined
} from '@ant-design/icons'
import {
    Button,
    Card,
    Col,
    Divider,
    Empty,
    Form,
    message,
    Modal,
    Row,
    Spin,
    TimePicker,
    Typography
} from 'antd'
import { Schedule } from './../components'
import { weekdayName } from './../utils/helpers'

const useStyles = createUseStyles({
    iconButton: {
        cursor: 'pointer',
        position: 'relative',
        top: 6,
        fontSize: 24,
        color: 'rgba(0, 0, 0, 0.45)'
    },
    buttonContainer: {
        textAlign: 'center',
        marginBottom: 24
    },
    spin: {
        textAlign: 'center',
        paddingTop: 24,
        paddingBottom: 24
    }
})

const defaultRule = {
    weekday: '',
    date: '',
    intervals: []
}

const defaultErrors = {
    //
}

const SetSchedule = props => {
    const {
        ruleableId,
        ruleableType
    } = props
    const {
        iconButton,
        buttonContainer,
        spin
    } = useStyles()

    const [
        key,
        setKey
    ] = React.useState(uuid())
    const [
        errors,
        setErrors
    ] = React.useState(defaultErrors)
    const [
        visible,
        setVisible
    ] = React.useState(false)
    const [
        rules,
        setRules
    ] = React.useState([])
    const [
        weekday,
        setWeekday
    ] = React.useState('')
    const [
        intervals,
        setIntervals
    ] = React.useState([])

    const findRuleByWeekday = day => rules.find(({ weekday }) => weekday === day) || defaultRule

    const getIntervalsByWeekDay = day => findRuleByWeekday(day).intervals

    const setRule = day => {
        const items = getIntervalsByWeekDay(day)
        setWeekday(day)
        setIntervals(items.length ? items : [{ from: '', to: '' }])
        setVisible(true)
    }

    const validateStatus = index => {
        return errors[`intervals.${index}.from`] || errors[`intervals.${index}.to`]
            ? 'error'
            : ''
    }

    const getInterval = index => {
        if (
            !intervals[index].from ||
            !intervals[index].to
        ) {
            return [
                null,
                null
            ]
        }

        return [
            moment(intervals[index].from, 'HH:mm:ss'),
            moment(intervals[index].to, 'HH:mm:ss')
        ]
    }

    const onChange = (index, time) => {
        const [
            from,
            to
        ] = time

        const array = [...intervals]
        array[index] = {
            from,
            to
        }

        setIntervals(array)
    }

    const add = () => {
        setIntervals([
            ...intervals,
            {
                from: '',
                to: ''
            }
        ])
    }

    const destroy = value => {
        setIntervals(intervals.filter((_, index) => index !== value))
    }

    const save = makeRequest => {
        setErrors(defaultErrors)
        makeRequest()
    }

    const onError = err => {
        if (err.response && err.response.status === 422) {
            setErrors({
                ...defaultErrors,
                ...err.response.data.errors
            })
        }
    }

    const onSuccess = () => {
        message.success('Цагийн тохиргоо амжилттай хадгаллаа')
        setKey(uuid())
        setVisible(false)
    }

    return (
        <div>
            <Card>
                <Typography.Text>
                    7 хоногоор цаг тохируулах
                </Typography.Text>
                <Divider />
                <div key={key}>
                    <Get
                        url={generatePath('api/:ruleableType/:ruleableId/rules', { ruleableType, ruleableId })}
                        onSuccess={({ data }) => setRules(data)}
                        params={{
                            type: 'weekday'
                        }}
                    >
                        {(error, response, loading) => {
                            if (loading) {
                                return (
                                    <div className={spin}>
                                        <Spin />
                                    </div>
                                )
                            }

                            return (
                                <div>
                                    <Schedule
                                        day="Даваа"
                                        intervals={getIntervalsByWeekDay('monday')}
                                        onClickEdit={() => setRule('monday')}
                                    />
                                    <Divider />
                                    <Schedule
                                        day="Мягмар"
                                        intervals={getIntervalsByWeekDay('tuesday')}
                                        onClickEdit={() => setRule('tuesday')}
                                    />
                                    <Divider />
                                    <Schedule
                                        day="Лхагва"
                                        intervals={getIntervalsByWeekDay('wednesday')}
                                        onClickEdit={() => setRule('wednesday')}
                                    />
                                    <Divider />
                                    <Schedule
                                        day="Пүрэв"
                                        intervals={getIntervalsByWeekDay('thursday')}
                                        onClickEdit={() => setRule('thursday')}
                                    />
                                    <Divider />
                                    <Schedule
                                        day="Баасан"
                                        intervals={getIntervalsByWeekDay('friday')}
                                        onClickEdit={() => setRule('friday')}
                                    />
                                    <Divider />
                                    <Schedule
                                        day="Бямба"
                                        intervals={getIntervalsByWeekDay('saturday')}
                                        onClickEdit={() => setRule('saturday')}
                                    />
                                    <Divider />
                                    <Schedule
                                        day="Ням"
                                        intervals={getIntervalsByWeekDay('sunday')}
                                        onClickEdit={() => setRule('sunday')}
                                    />
                                </div>
                            )
                        }}
                    </Get>
                </div>
            </Card>
            <Modal
                title={weekdayName(weekday)}
                onCancel={() => setVisible(false)}
                footer={null}
                visible={visible}
                closable={false}
            >
                <Post
                    url="api/rules/intervals"
                    isReady={false}
                    data={{
                        intervals,
                        weekday,
                        ruleableId,
                        ruleableType
                    }}
                    onError={onError}
                    onSuccess={onSuccess}
                >
                    {(error, response, loading, makeRequest) => (
                        <Form onFinish={() => save(makeRequest)}>
                            {intervals.length === 0 && (
                                <div>
                                    <Empty
                                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        description="Цагийн тохиргоо байхгүй"
                                    />
                                    <div className={buttonContainer}>
                                        <Button
                                            type="primary"
                                            onClick={add}
                                        >
                                            Цаг тохируулах
                                        </Button>
                                    </div>
                                </div>
                            )}
                            {intervals.map(({ from, to }, index) => (
                                <Row
                                    key={index}
                                    gutter={16}
                                >
                                    <Col
                                        xs={16}
                                        sm={20}
                                    >
                                        <Form.Item validateStatus={validateStatus(index)}>
                                            <TimePicker.RangePicker
                                                value={getInterval(index)}
                                                onChange={(time, timeString) => onChange(index, timeString)}
                                                format="HH:mm"
                                                size="large"
                                                placeholder={[
                                                    'Эхлэх', 'Дуусах'
                                                ]}
                                                allowClear
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col
                                        xs={4}
                                        sm={2}
                                    >
                                        <MinusCircleOutlined
                                            className={iconButton}
                                            onClick={() => destroy(index)}
                                        />
                                    </Col>
                                    <Col
                                        xs={4}
                                        sm={2}
                                    >
                                        {index === 0 &&
                                            <PlusCircleOutlined
                                                className={iconButton}
                                                onClick={add}
                                            />
                                        }
                                    </Col>
                                </Row>
                            ))}
                            <Button
                                loading={loading}
                                type="primary"
                                htmlType="submit"
                                ghost
                            >
                                Хадгалах
                            </Button>
                        </Form>
                    )}
                </Post>
            </Modal>
        </div>
    )
}

export default SetSchedule