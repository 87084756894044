import * as React from 'react'
import { createUseStyles } from 'react-jss'
import {
    Avatar,
    Button,
    Typography
} from 'antd'

const useStyles = createUseStyles({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingTop: 16,
        paddingBottom: 16
    },
    avatar: {
        marginRight: 16,
        minWidth: 40,
        backgroundColor: 'transparent',
        color: 'rgba(0, 0, 0, 0.45)'
    },
    body: {
        display: 'inline-flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    }
})

const ListItem = props => {
    const {
        container,
        avatar,
        body
    } = useStyles()
    const {
        actionText,
        onClickAction,
        icon,
        title,
        description
    } = props

    return (
        <div className={container}>
            <Avatar
                size={40}
                icon={icon}
                className={avatar}
            />
            <div className={body}>
                {title &&
                    <Typography.Text strong>
                        {title}
                    </Typography.Text>
                }
                {description &&
                    <Typography.Text type="secondary">
                        {description}
                    </Typography.Text>
                }
            </div>
            <div style={{ flex: 1 }}></div>
            {onClickAction &&
                <Button
                    onClick={onClickAction}
                    type="primary"
                    ghost
                >
                    {actionText}
                </Button>
            }
        </div>
    )
}

export default ListItem
