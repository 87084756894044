import * as React from 'react'
import useMedia from 'use-media'
import {
    useParams,
    Link
} from 'react-router-dom'
import { createUseStyles } from 'react-jss'
import { MenuOutlined } from '@ant-design/icons'
import {
    Button,
    Drawer,
    Menu
} from 'antd'

const useStyles = createUseStyles({
    menu: {
        borderRadius: 4,
        border: '1px solid #f0f0f0'
    },
    drawerMenu: {
        borderRight: 0
    },
    button: {
        marginBottom: 36
    }
})

const AppSettingsMenu = props => {
    const isWide = useMedia({
        minWidth: '768px'
    })

    const { slug } = useParams()
    const {
        menu,
        drawerMenu,
        button
    } = useStyles()

    const [
        drawer,
        setDrawer
    ] = React.useState(false)

    return (
        <div>
            {!isWide &&
                <Button
                    type="primary"
                    className={button}
                    onClick={() => setDrawer(true)}
                    icon={
                        <MenuOutlined />
                    }
                    ghost
                />
            }
            <Drawer
                placement="left"
                closable={false}
                visible={drawer}
                onClose={() => setDrawer(false)}
            >
                <Menu
                    mode="inline"
                    className={drawerMenu}
                >
                    <Menu.Item>
                        <Link to={`/org/${slug}/settings`}>
                            Ерөнхий
                        </Link>
                    </Menu.Item>
                    <Menu.Divider />
                    <Menu.Item>
                        <Link to={`/org/${slug}/settings/services-employees`}>
                            Үйлчилгээ, ажилчин
                        </Link>
                    </Menu.Item>
                    <Menu.Divider />
                    <Menu.Item>
                        <Link to={`/org/${slug}/settings/admins`}>
                            Админ
                        </Link>
                    </Menu.Item>
                    <Menu.Divider />
                    <Menu.Item>
                        <Link to={`/org/${slug}/settings/custom-fields`}>
                            Нэмэлт талбар
                        </Link>
                    </Menu.Item>
                </Menu>
            </Drawer>
            {isWide &&
                <Menu
                    mode="inline"
                    className={menu}
                >
                    <Menu.Item>
                        <Link to={`/org/${slug}/settings`}>
                            Ерөнхий
                        </Link>
                    </Menu.Item>
                    <Menu.Divider />
                    <Menu.Item>
                        <Link to={`/org/${slug}/settings/services-employees`}>
                            Үйлчилгээ, ажилчин
                        </Link>
                    </Menu.Item>
                    <Menu.Divider />
                    <Menu.Item>
                        <Link to={`/org/${slug}/settings/admins`}>
                            Админ
                        </Link>
                    </Menu.Item>
                    <Menu.Divider />
                    <Menu.Item>
                        <Link to={`/org/${slug}/settings/custom-fields`}>
                            Нэмэлт талбар
                        </Link>
                    </Menu.Item>
                </Menu>
            }
        </div>
    )
}

export default AppSettingsMenu