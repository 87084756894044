import * as React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { createUseStyles } from 'react-jss'
import { UserOutlined } from '@ant-design/icons'
import {
    Avatar,
    Menu,
    Popover
} from 'antd'
import {
    setAccessToken,
    setUser
} from './../../redux/auth/actions'

const useStyles = createUseStyles({
    avatar: {
        backgroundColor: '#fff',
        color: '#001529'
    }
})

const UserMenu = props => {
    const {
        avatar
    } = useStyles()

    const logout = () => {
        props.setAccessToken(null)
        props.setUser(null)
    }

    const content = (
        <Menu>
            <Menu.Item>
                <Link to="/user">
                    Миний мэдээлэл
                </Link>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item onClick={logout}>
                Гарах
            </Menu.Item>
        </Menu>
    )

    return (
        <Popover
            placement="bottomRight"
            content={content}
            trigger="click"
        >
            <Avatar
                className={avatar}
                size={40}
                icon={
                    <UserOutlined />
                }
            />
        </Popover>
    )
}

const mapDispatchToProps = {
    setAccessToken,
    setUser
}

export default connect(
    null,
    mapDispatchToProps
)(UserMenu)