import axios from 'axios'
import { store } from './../redux/store'
import {
    setAccessToken,
    setUser
} from './../redux/auth/actions'

const baseURL = process.env.REACT_APP_API_URL

const API = axios.create({
    baseURL,
    headers: {
        'X-Requested-With': 'XMLHttpRequest'
    }
})

const handleRequest = config => {
    const state = store.getState()

    if (state.auth.accessToken) {
        Object.assign(config.headers, {
            Authorization: `Bearer ${state.auth.accessToken}`
        })
    }

    return config
}

const handleResponse = ({ data }) => data

const handleError = error => {
    if (error.response && error.response.status === 401) {
        store.dispatch(setUser(null))
        store.dispatch(setAccessToken(null))
    }

    return Promise.reject(error)
}

API.interceptors.request.use(
    handleRequest,
    handleError
)

API.interceptors.response.use(
    handleResponse,
    handleError
)

export default axios
export {
    API
}