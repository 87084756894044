import * as React from 'react'
import NumberFormat from 'react-number-format'
import { createUseStyles } from 'react-jss'
import {
    CalendarOutlined,
    DeleteOutlined,
    EditOutlined
} from '@ant-design/icons'
import {
    Card,
    Col,
    Row,
    Tag,
    Typography
} from 'antd'

const useStyles = createUseStyles({
    description: {
        marginTop: 4,
        marginBottom: 16
    }
})

const Service = props => {
    const {
        description
    } = useStyles()
    const {
        name,
        duration,
        price,
        notes,
        onClickEdit,
        onClickDelete,
        onClickCalendar
    } = props

    return (
        <div>
            <Card
                actions={[
                    <EditOutlined onClick={onClickEdit} />,
                    <DeleteOutlined onClick={onClickDelete} />,
                    <CalendarOutlined onClick={onClickCalendar} />
                ]}
            >
                <div>
                    <Typography.Text strong>
                        {name}
                    </Typography.Text>
                </div>
                <div className={description}>
                    <Typography.Text type="secondary">
                        {notes}
                    </Typography.Text>
                </div>
                <Row
                    justify="space-between"
                    gutter={16}
                >
                    <Col>
                        <Tag color="#ea345c">
                            {duration} мин
                        </Tag>
                    </Col>
                    <Col>
                        <Typography.Text strong>
                            <NumberFormat
                                displayType="text"
                                suffix="₮"
                                value={price}
                                thousandSeparator
                            />
                        </Typography.Text>
                    </Col>
                </Row>
            </Card>
        </div>
    )
}

export default Service