import * as React from 'react'
import moment from 'moment'
import { useMediaQuery } from 'beautiful-react-hooks'
import {
    useParams,
    generatePath
} from 'react-router-dom'
import { createUseStyles } from 'react-jss'
import { Get } from 'react-axios'
import {
    Badge,
    Card,
    Calendar as AntCalendar,
    Popover,
    Typography
} from 'antd'
import { App } from './../layouts'
import { Appointment } from './../components'
import { Spinner } from './../components/common'

const useStyles = createUseStyles({
    title: {
        marginBottom: 24
    },
    dateCell: {
        flex: 1,
        textAlign: 'right'
    },
    dateCellMobile: {
        flex: 1,
        textAlign: 'center'
    }
})

const Calendar = props => {
    const isMobile = useMediaQuery('(max-width: 767px)')

    const { slug: id } = useParams()
    const {
        title,
        dateCell,
        dateCellMobile
    } = useStyles()

    const startOfMonth = (date = moment()) => {
        return date.startOf('month').format('YYYY-MM-DD')
    }

    const endOfMonth = (date = moment()) => {
        return date.endOf('month').format('YYYY-MM-DD')
    }

    const [
        from,
        setFrom
    ] = React.useState(startOfMonth())
    const [
        to,
        setTo
    ] = React.useState(endOfMonth())
    const [
        appointments,
        setAppointments
    ] = React.useState([])

    const onChange = (value, mode) => {
        if (mode === 'month') {
            setFrom(startOfMonth(value))
            setTo(endOfMonth(value))
        }
    }

    const dateCellRender = value => {
        const array = appointments.filter(({ date }) => value.isSame(date, 'day'))
        const content = (
            <div>
                {array.map(({ id, appointable: { name, surname, phone }, from, to, service }) => (
                    <Appointment
                        id={id}
                        name={name}
                        surname={surname}
                        phone={phone}
                        from={from}
                        to={to}
                        serviceName={service.name}
                    />
                ))}
            </div>
        )

        if (array.length === 0) {
            return null
        }

        if (isMobile) {
            return (
                <div className={dateCellMobile}>
                    <Badge status="success" />
                </div>
            )
        }

        return (
            <div className={dateCell}>
                <Popover
                    content={content}
                    trigger="click"
                >
                    <Typography.Title level={5}>
                        {array.length}
                    </Typography.Title>
                    <Typography.Text type="secondary">
                        захиалга
                    </Typography.Text>
                </Popover>
            </div>
        )
    }

    return (
        <App>
            <div className={title}>
                <Typography.Title level={4}>
                    Календарь
                </Typography.Title>
            </div>
            <Get
                url={generatePath('api/organizations/:id/appointments', { id })}
                onSuccess={({ data }) => setAppointments(data)}
                params={{
                    perPage: 0,
                    from,
                    to
                }}
            >
                {(error, response, loading) => (
                    <div>
                        <Spinner visible={loading} />
                        <Card>
                            <AntCalendar
                                fullscreen={!isMobile}
                                onPanelChange={onChange}
                                dateCellRender={dateCellRender}
                            />
                        </Card>
                    </div>
                )}
            </Get>
        </App>
    )
}

export default Calendar