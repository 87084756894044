import * as React from 'react'
import {
    useParams,
    generatePath
} from 'react-router-dom'
import { createUseStyles } from 'react-jss'
import { Get } from 'react-axios'
import {
    Card,
    Col,
    Divider,
    Row,
    Space,
    Typography
} from 'antd'
import { App } from './../layouts'
import {
    HomeChart,
    HomeStatistic
} from './../components'

const useStyles = createUseStyles({
    title: {
        marginTop: 24,
        marginBottom: 16
    },
    chartContainer: {
        height: 170
    },
    '@media screen and (min-width: 768px)': {
        chartContainer: {
            height: 355
        }
    },
    divider: {
        marginBottom: 0
    }
})

const Home = props => {
    const { slug: id } = useParams()
    const {
        title,
        chartContainer,
        divider
    } = useStyles()

    const [
        dayCount,
        setDayCount
    ] = React.useState(0)
    const [
        weekCount,
        setWeekCount
    ] = React.useState(0)
    const [
        monthCount,
        setMonthCount
    ] = React.useState(0)
    const [
        counts,
        setCounts
    ] = React.useState([])

    const setData = data => {
        const {
            day,
            week,
            month,
            counts
        } = data

        setDayCount(day)
        setWeekCount(week)
        setMonthCount(month)
        setCounts(counts)
    }

    return (
        <App>
            <Typography.Title level={4}>
                Нүүр
            </Typography.Title>
            <Divider className={divider} />
            <Get
                url={generatePath('api/organizations/:id/appointments/report', { id })}
                onSuccess={setData}
                params={{
                    perPage: 0
                }}
            >
                {(error, response, loading) => (
                    <Row gutter={24}>
                        <Col
                            xs={24}
                            md={8}
                        >
                            <div className={title}>
                                <Typography.Title level={5}>
                                    Захиалгын тоо
                                </Typography.Title>
                            </div>
                            <Space direction="vertical">
                                <HomeStatistic
                                    title="Өнөөдөр"
                                    value={dayCount}
                                />
                                <HomeStatistic
                                    title="Энэ 7 хоног"
                                    value={weekCount}
                                />
                                <HomeStatistic
                                    title="Энэ сар"
                                    value={monthCount}
                                />
                            </Space>
                        </Col>
                        <Col
                            xs={24}
                            md={16}
                        >
                            <div className={title}>
                                <Typography.Title level={5}>
                                    Сүүлийн 6 сарын харьцуулалт
                                </Typography.Title>
                            </div>
                            <Card className={chartContainer}>
                                <HomeChart
                                    data={counts}
                                    loading={loading}
                                />
                            </Card>
                        </Col>
                    </Row>
                )}
            </Get>
        </App>
    )
}

export default Home