import * as React from 'react'
import { connect } from 'react-redux'
import {
    Route,
    Redirect
} from 'react-router-dom'

const UserRoute = props => {
    const {
        user,
        ...other
    } = props

    return user
        ? <Route {...other} />
        : <Redirect to="/login" />
}

const mapStateToProps = state => ({
    user: state.auth.user
})

export default connect(mapStateToProps)(UserRoute)
