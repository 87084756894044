import * as React from 'react'
import {
    Link,
    useParams,
    generatePath
} from 'react-router-dom'
import { withRouter } from 'react-router'
import { createUseStyles } from 'react-jss'
import { Get } from 'react-axios'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import {
    Button,
    Divider,
    Empty,
    Modal,
    Space,
    Spin,
    Typography
} from 'antd'
import { Employee } from './../components'
import { API } from './../plugins/axios'

const useStyles = createUseStyles({
    title: {
        marginBottom: 16
    },
    subtitle: {
        marginTop: 2,
        marginBottom: 16
    },
    spin: {
        textAlign: 'center'
    }
})

const Employees = props => {
    const { slug } = useParams()
    const {
        title,
        subtitle,
        spin
    } = useStyles()

    const [
        employees,
        setEmployees
    ] = React.useState([])

    const destroy = id => {
        Modal.confirm({
            title: 'Устгах',
            icon: <ExclamationCircleOutlined />,
            content: 'Та энэ ажилчныг устгахыг зөвшөөрч байна уу?',
            okText: 'Тийм',
            cancelText: 'Үгүй',
            onOk: async () => {
                try {
                    await API.delete(generatePath('api/employees/:id', { id }))
                    setEmployees(employees.filter(employee => employee.id !== id))
                } catch (err) {
                    //
                }
            }
        })
    }

    return (
        <div>
            <Typography.Title level={5}>
                Ажилчин нэмэх
            </Typography.Title>
            <div className={subtitle}>
                <Typography.Text type="secondary">
                    Та шинэ ажилчин байгууллага дээрээ нэмэх боломжтой
                </Typography.Text>
            </div>
            <Link
                component={Button}
                type="primary"
                to={`/org/${slug}/settings/employees/create`}
                ghost
            >
                Шинэ
            </Link>
            <Divider />
            <div className={title}>
                <Typography.Title level={5}>
                    Ажилчид
                </Typography.Title>
            </div>
            <Get
                url={generatePath('api/organizations/:id/employees', { id: slug })}
                onSuccess={({ data }) => setEmployees(data)}
                params={{
                    perPage: 0,
                    sortBy: 'name',
                    sortOrder: 'asc'
                }}
            >
                {(error, response, loading) => {
                    if (loading) {
                        return (
                            <div className={spin}>
                                <Spin />
                            </div>
                        )
                    }

                    if (!employees.length) {
                        return (
                            <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description="Ажилчин бүртгээгүй байна"
                            />
                        )
                    }

                    return (
                        <Space
                            size="middle"
                            direction="vertical"
                        >
                            {employees.map(({ id, name, avatar }) => (
                                <Employee
                                    key={id}
                                    name={name}
                                    avatar={avatar}
                                    onClickEdit={() => props.history.push(generatePath('/org/:slug/settings/employees/:id', {
                                        slug,
                                        id
                                    }))}
                                    onClickDelete={() => destroy(id)}
                                />
                            ))}
                        </Space>
                    )
                }}
            </Get>
        </div>
    )
}

export default withRouter(Employees)