import * as React from 'react'
import _ from 'lodash'
import { uuid } from 'uuidv4'
import {
    generatePath,
    useParams
} from 'react-router-dom'
import { createUseStyles } from 'react-jss'
import {
    Get,
    Put
} from 'react-axios'
import { MinusCircleOutlined } from '@ant-design/icons'
import {
    Button,
    Checkbox,
    Col,
    Divider,
    Form,
    Input,
    message,
    Row,
    Typography
} from 'antd'
import { AppSettings } from './../layouts'
import { Spinner } from './../components/common'

const useStyles = createUseStyles({
    subtitle: {
        marginBottom: 16
    },
    minusButton: {
        cursor: 'pointer',
        position: 'relative',
        top: 6,
        fontSize: 24,
        color: 'rgba(0, 0, 0, 0.45)'
    }
})

const defaultErrors = {
    name: null,
    duration: null,
    price: null,
    notes: null,
    appointable: null
}

const ServicesUpdate = props => {
    const {
        id,
        slug
    } = useParams()
    const {
        subtitle,
        minusButton
    } = useStyles()

    const [
        errors,
        setErrors
    ] = React.useState(defaultErrors)
    const [
        name,
        setName
    ] = React.useState('')
    const [
        duration,
        setDuration
    ] = React.useState('')
    const [
        price,
        setPrice
    ] = React.useState('')
    const [
        notes,
        setNotes
    ] = React.useState('')
    const [
        appointable,
        setAppointable
    ] = React.useState(true)
    const [
        serviceItems,
        setServiceItems
    ] = React.useState([''])
    const [
        key,
        setKey
    ] = React.useState(uuid())

    const onValuesChange = (_, { serviceItems }) => setServiceItems(serviceItems)

    const setData = data => {
        const {
            name,
            duration,
            price,
            notes,
            appointable,
            serviceItems
        } = data

        setName(name)
        setDuration(duration)
        setPrice(price)
        setNotes(notes)
        setAppointable(appointable)
        setServiceItems(serviceItems.map(({ name }) => name))
        setKey(uuid())
    }

    const validateStatus = prop => {
        return errors[prop] === null
            ? ''
            : 'error'
    }

    const save = makeRequest => {
        setErrors(defaultErrors)
        makeRequest()
    }

    const onError = err => {
        if (err.response && err.response.status === 422) {
            setErrors({
                ...defaultErrors,
                ...err.response.data.errors
            })
        }
    }

    const onSuccess = () => message.success('Үйлчилгээ амжилттай хадгаллаа')

    return (
        <AppSettings>
            <Get
                url={generatePath('api/services/:id', { id })}
                onSuccess={({ data }) => setData(data)}
            >
                {(error, response, loading) => (
                    <div>
                        <Spinner visible={loading} />
                        <Typography.Title level={4}>
                            Үйлчилгээ
                        </Typography.Title>
                        <Divider />
                        <Row gutter={16}>
                            <Col
                                xs={24}
                                lg={12}
                            >
                                <Put
                                    url={generatePath('api/services/:id', { id })}
                                    isReady={false}
                                    data={{
                                        name,
                                        duration,
                                        price,
                                        notes,
                                        appointable,
                                        serviceItems: serviceItems.filter(name => _.isString(name) && !!name.trim()).map(name => ({ name })),
                                        organizationId: slug
                                    }}
                                    onError={onError}
                                    onSuccess={onSuccess}
                                >
                                    {(error, response, loading, makeRequest) => (
                                        <div key={key}>
                                            <Form
                                                onValuesChange={onValuesChange}
                                                onFinish={() => save(makeRequest)}
                                                initialValues={{
                                                    serviceItems
                                                }}
                                            >
                                                <Form.Item
                                                    validateStatus={validateStatus('name')}
                                                    help={errors.name}
                                                >
                                                    <Input
                                                        size="large"
                                                        placeholder="Нэр"
                                                        value={name}
                                                        onChange={e => setName(e.target.value)}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    validateStatus={validateStatus('duration')}
                                                    help={errors.duration}
                                                >
                                                    <Input
                                                        size="large"
                                                        placeholder="Хугацаа (минут)"
                                                        value={duration}
                                                        onChange={e => setDuration(e.target.value)}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    validateStatus={validateStatus('price')}
                                                    help={errors.price}
                                                >
                                                    <Input
                                                        size="large"
                                                        placeholder="Үнэ"
                                                        value={price}
                                                        onChange={e => setPrice(e.target.value)}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    validateStatus={validateStatus('notes')}
                                                    help={errors.notes}
                                                >
                                                    <Input.TextArea
                                                        size="large"
                                                        placeholder="Тайлбар"
                                                        rows={4}
                                                        value={notes}
                                                        onChange={e => setNotes(e.target.value)}
                                                    />
                                                </Form.Item>
                                                <div className={subtitle}>
                                                    <Typography.Title level={5}>
                                                        Үйлчилгээнд багтсан зүйлс
                                                    </Typography.Title>
                                                </div>
                                                <Form.List name="serviceItems">
                                                    {(fields, { add, remove }) => (
                                                        <div>
                                                            {fields.map((field, index) => (
                                                                <Row
                                                                    justify="space-between"
                                                                    gutter={16}
                                                                    key={index}
                                                                >
                                                                    <Col flex="auto">
                                                                        <Form.Item {...field}>
                                                                            <Input
                                                                                size="large"
                                                                                placeholder="Нэр"
                                                                            />
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col>
                                                                        <MinusCircleOutlined
                                                                            className={minusButton}
                                                                            onClick={() => remove(field.name)}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            ))}
                                                            <Form.Item>
                                                                <Button
                                                                    type="dashed"
                                                                    onClick={() => add()}
                                                                >
                                                                    Нэмэх
                                                                </Button>
                                                            </Form.Item>
                                                        </div>
                                                    )}
                                                </Form.List>
                                                <Form.Item
                                                    extra="Хэрвээ үйлчилгээний цаг захиалахад ажилчин сонгох шаардлагагүй бол дээрхийг чагтална уу"
                                                    validateStatus={validateStatus('appointable')}
                                                    help={errors.appointable}
                                                >
                                                    <Checkbox
                                                        checked={appointable}
                                                        onChange={e => setAppointable(e.target.checked)}
                                                    >
                                                        Ажилчин хамаарахгүй
                                                    </Checkbox>
                                                </Form.Item>
                                                <Button
                                                    loading={loading}
                                                    type="primary"
                                                    htmlType="submit"
                                                    ghost
                                                >
                                                    Хадгалах
                                                </Button>
                                            </Form>
                                        </div>
                                    )}
                                </Put>
                            </Col>
                        </Row>
                    </div>
                )}
            </Get>
        </AppSettings>
    )
}

export default ServicesUpdate