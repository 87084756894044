import * as React from 'react'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import { createUseStyles } from 'react-jss'
import { connect } from 'react-redux'
import { Post } from 'react-axios'
import { LockOutlined } from '@ant-design/icons'
import {
    Button,
    Col,
    Form,
    Input,
    Row,
    Typography
} from 'antd'
import {
    setAccessToken,
    setUser
} from './../redux/auth/actions'

const useStyles = createUseStyles({
    app: {
        padding: 20
    },
    action: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    logo: {
        display: 'block',
        margin: '24px auto'
    },
    text: {
        margin: '24px 0 32px',
        display: 'block',
        textAlign: 'center'
    }
})

const defaultErrors = {
    password: null,
    passwordConfirmation: null
}

const Register = props => {
    const {
        app,
        action,
        logo,
        text
    } = useStyles()

    const {
        id,
        token
    } = queryString.parse(useLocation().search)

    const [
        errors,
        setErrors
    ] = React.useState(defaultErrors)
    const [
        password,
        setPassword
    ] = React.useState('')
    const [
        passwordConfirmation,
        setPasswordConfirmation
    ] = React.useState('')

    const validateStatus = prop => {
        return errors[prop] === null
            ? ''
            : 'error'
    }

    const reset = makeRequest => {
        setErrors(defaultErrors)
        makeRequest()
    }

    const onError = err => {
        if (err.response && err.response.status === 422) {
            setErrors({
                ...defaultErrors,
                ...err.response.data.errors
            })
        }
    }

    const onSuccess = ({ accessToken, user }) => {
        props.setAccessToken(accessToken)
        props.setUser(user)
    }

    return (
        <div>
            <div className={app}>
                <Row
                    gutter={16}
                    justify="center"
                >
                    <Col
                        xs={24}
                        sm={20}
                        md={16}
                        lg={12}
                        xl={8}
                        xxl={6}
                    >
                        <Post
                            url="api/admin/register"
                            isReady={false}
                            data={{
                                password,
                                passwordConfirmation,
                                id,
                                token
                            }}
                            onError={onError}
                            onSuccess={onSuccess}
                        >
                            {(error, response, loading, makeRequest) => (
                                <Form onFinish={() => reset(makeRequest)}>
                                    <img
                                        height="30"
                                        className={logo}
                                        alt="toblo"
                                        src="/static/images/logo.png"
                                    />
                                    <Typography.Text
                                        className={text}
                                        type="secondary"
                                    >
                                        Шинэ нууц үгээ оруулан бүртгэлээ <br /> баталгаажуулна уу
                                    </Typography.Text>
                                    <Form.Item
                                        validateStatus={validateStatus('password')}
                                        help={errors.password}
                                    >
                                        <Input.Password
                                            size="large"
                                            placeholder="Шинэ нууц үг"
                                            value={password}
                                            onChange={e => setPassword(e.target.value)}
                                            prefix={
                                                <LockOutlined />
                                            }
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        validateStatus={validateStatus('passwordConfirmation')}
                                        help={errors.passwordConfirmation}
                                    >
                                        <Input.Password
                                            size="large"
                                            placeholder="Шинэ нууц үг давтах"
                                            value={passwordConfirmation}
                                            onChange={e => setPasswordConfirmation(e.target.value)}
                                            prefix={
                                                <LockOutlined />
                                            }
                                        />
                                    </Form.Item>
                                    <div className={action}>
                                        <Button
                                            loading={loading}
                                            type="primary"
                                            htmlType="submit"
                                            ghost
                                        >
                                            Бүртгүүлэх
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </Post>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

const mapDispatchToProps = {
    setAccessToken,
    setUser
}

export default connect(null, mapDispatchToProps)(Register)