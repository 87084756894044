import * as React from 'react'
import {
    Card,
    Statistic
} from 'antd'

const HomeStatistic = props => (
    <Card>
        <Statistic {...props} />
    </Card>
)

export default HomeStatistic