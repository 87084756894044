import * as React from 'react'
import moment from 'moment'
import { createUseStyles } from 'react-jss'
import {
    useParams,
    generatePath
} from 'react-router-dom'
import { Get } from 'react-axios'
import {
    CalendarOutlined,
    FileOutlined,
    InfoCircleOutlined,
    PhoneOutlined,
    UserOutlined
} from '@ant-design/icons'
import {
    Card,
    Col,
    Divider,
    Empty,
    Row,
    Spin,
    Typography
} from 'antd'
import { App } from './../layouts'
import {
    List,
    ListItem
} from './../components/common'
import { formatHumanize } from './../utils/helpers'

const useStyles = createUseStyles({
    title: {
        marginBottom: 16
    },
    list: {
        paddingTop: 0,
        paddingBottom: 0
    },
    divider: {
        margin: 0
    },
    spin: {
        textAlign: 'center'
    }
})

const Appointment = props => {
    const { id } = useParams()
    const {
        title,
        list,
        divider,
        spin
    } = useStyles()

    const [
        fullName,
        setFullName
    ] = React.useState('')
    const [
        phone,
        setPhone
    ] = React.useState('')
    const [
        serviceName,
        setServiceName
    ] = React.useState('')
    const [
        employeeName,
        setEmployeeName
    ] = React.useState('')
    const [
        from,
        setFrom
    ] = React.useState('')
    const [
        to,
        setTo
    ] = React.useState('')
    const [
        date,
        setDate
    ] = React.useState('')
    const [
        metas,
        setMetas
    ] = React.useState([])

    const setData = data => {
        const {
            date,
            from,
            to,
            metas,
            appointable,
            service,
            employeeService
        } = data

        setFullName(`${appointable.surname} ${appointable.name}`)
        setPhone(appointable.phone)
        setServiceName(service.name)
        setEmployeeName(employeeService ? employeeService.employee.name : '')
        setFrom(from)
        setTo(to)
        setDate(date)
        setMetas(metas)
    }

    return (
        <App>
            <Row
                gutter={16}
                justify="center"
            >
                <Col
                    xs={24}
                    sm={20}
                    md={16}
                    lg={12}
                >
                    <div className={title}>
                        <Typography.Title level={4}>
                            Захиалга
                        </Typography.Title>
                    </div>
                    <Card>
                        <Get
                            url={generatePath('api/appointments/:id', { id })}
                            onSuccess={({ data }) => setData(data)}
                        >
                            {(error, response, loading) => {
                                if (error) {
                                    return (
                                        <Empty
                                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                                            description={error.message}
                                        />
                                    )
                                }

                                if (loading) {
                                    return (
                                        <div className={spin}>
                                            <Spin />
                                        </div>
                                    )
                                }

                                return (
                                    <List className={list}>
                                        <ListItem
                                            title="Овог, нэр"
                                            description={fullName}
                                            icon={
                                                <UserOutlined />
                                            }
                                        />
                                        <Divider className={divider} />
                                        <ListItem
                                            title="Утасны дугаар"
                                            description={phone}
                                            icon={
                                                <PhoneOutlined />
                                            }
                                        />
                                        <Divider className={divider} />
                                        <ListItem
                                            title="Үйлчилгээ"
                                            description={serviceName}
                                            icon={
                                                <InfoCircleOutlined />
                                            }
                                        />
                                        {!!employeeName && (
                                            <div>
                                                <Divider className={divider} />
                                                <ListItem
                                                    title="Ажилчин"
                                                    description={employeeName}
                                                    icon={
                                                        <InfoCircleOutlined />
                                                    }
                                                />
                                            </div>
                                        )}
                                        <Divider className={divider} />
                                        <ListItem
                                            title={`${moment(from, 'HH:mm:ss').format('HH:mm')} - ${moment(to, 'HH:mm:ss').format('HH:mm')}`}
                                            description={formatHumanize(date)}
                                            icon={
                                                <CalendarOutlined />
                                            }
                                        />
                                        {metas.map(({ key, value }, index) => (
                                            <div key={index}>
                                                <Divider className={divider} />
                                                <ListItem
                                                    title={key}
                                                    description={value}
                                                    icon={
                                                        <FileOutlined />
                                                    }
                                                />
                                            </div>
                                        ))}
                                    </List>
                                )
                            }}
                        </Get>
                    </Card>
                </Col>
            </Row>
        </App>
    )
}

export default Appointment