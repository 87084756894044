import * as React from 'react'
import { createUseStyles } from 'react-jss'
import { Post } from 'react-axios'
import { UserOutlined } from '@ant-design/icons'
import { Avatar as AntAvatar } from 'antd'

const useStyles = createUseStyles({
    input: {
        display: 'none !important'
    }
})

const Avatar = props => {
    const {
        input
    } = useStyles()
    const {
        url,
        onSuccess,
        ...other
    } = props

    const upload = (e, makeRequest) => {
        const data = new FormData()
        data.append('file', e.target.files[0])
        makeRequest({
            url,
            data,
            onSuccess,
            config: {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }
        })
    }

    return (
        <Post>
            {(error, response, loading, makeRequest) => (
                <label>
                    <input
                        onChange={e => upload(e, makeRequest)}
                        disabled={loading}
                        className={input}
                        type="file"
                    />
                    <AntAvatar
                        {...other}
                        icon={
                            <UserOutlined />
                        }
                    />
                </label>
            )}
        </Post>
    )
}

export default Avatar
