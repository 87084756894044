import * as React from 'react'
import {
    useParams,
    generatePath
} from 'react-router-dom'
import { createUseStyles } from 'react-jss'
import {
    Get,
    Post
} from 'react-axios'
import {
    ExclamationCircleOutlined,
    MinusCircleOutlined
} from '@ant-design/icons'
import {
    Button,
    Col,
    Divider,
    Empty,
    Form,
    Input,
    Modal,
    Row,
    Select,
    Spin,
    Typography
} from 'antd'
import { AppSettings } from './../layouts'
import { CustomField } from './../components'
import { API } from './../plugins/axios'

const useStyles = createUseStyles({
    subtitle: {
        marginTop: 2,
        marginBottom: 16
    },
    buttonContainer: {
        marginTop: 24,
        textAlign: 'center'
    },
    minusButton: {
        cursor: 'pointer',
        position: 'relative',
        top: 6,
        fontSize: 24,
        color: 'rgba(0, 0, 0, 0.45)'
    },
    spin: {
        textAlign: 'center'
    }
})

const defaultErrors = {
    name: null,
    type: null
}

const Admins = props => {
    const { slug: id } = useParams()
    const {
        subtitle,
        buttonContainer,
        minusButton,
        spin
    } = useStyles()

    const [
        errors,
        setErrors
    ] = React.useState(defaultErrors)
    const [
        name,
        setName
    ] = React.useState('')
    const [
        type,
        setType
    ] = React.useState('text') // text, textarea, national-id
    const [
        customFields,
        setCustomFields
    ] = React.useState([])
    const [
        visible,
        setVisible
    ] = React.useState(false)

    const validateStatus = prop => {
        return errors[prop] === null
            ? ''
            : 'error'
    }

    const create = makeRequest => {
        setErrors(defaultErrors)
        makeRequest()
    }

    const destroy = id => {
        Modal.confirm({
            title: 'Устгах',
            icon: <ExclamationCircleOutlined />,
            content: 'Та энэ нэмэлт талбарыг устгахыг зөвшөөрч байна уу?',
            okText: 'Тийм',
            cancelText: 'Үгүй',
            onOk: async () => {
                try {
                    await API.delete(generatePath('api/custom-fields/:id', { id }))
                    setCustomFields(customFields.filter(customField => customField.id !== id))
                } catch (err) {
                    //
                }
            }
        })
    }

    const onError = err => {
        if (err.response && err.response.status === 422) {
            setErrors({
                ...defaultErrors,
                ...err.response.data.errors
            })
        }
    }

    const onSuccess = ({ data }) => {
        setCustomFields([
            ...customFields,
            data
        ])
        setName('')
        setType('text')
        setVisible(false)
    }

    return (
        <AppSettings>
            <Typography.Title level={4}>
                Нэмэлт талбар
            </Typography.Title>
            <div className={subtitle}>
                <Typography.Text type="secondary">
                    Хэрэглэгч захиалга хийх үед нэмэлт талбар болон ашиглагдана
                </Typography.Text>
            </div>
            <Divider />
            <Get
                url={generatePath('api/organizations/:id/custom-fields', { id })}
                onSuccess={({ data }) => setCustomFields(data)}
                params={{
                    perPage: 0,
                    sortOrder: 'asc'
                }}
            >
                {(error, response, loading) => {
                    if (loading) {
                        return (
                            <div className={spin}>
                                <Spin />
                            </div>
                        )
                    }

                    if (!customFields.length) {
                        return (
                            <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description="Нэмэлт талбар байхгүй байна"
                            />
                        )
                    }

                    return (
                        <div>
                            <Form>
                                {customFields.map(({ id, name, type }) => (
                                    <Row
                                        justify="space-between"
                                        gutter={16}
                                        key={id}
                                    >
                                        <Col flex="auto">
                                            <Form.Item>
                                                <CustomField
                                                    size="large"
                                                    type={type}
                                                    placeholder={name}
                                                    readOnly
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col>
                                            <MinusCircleOutlined
                                                className={minusButton}
                                                onClick={() => destroy(id)}
                                            />
                                        </Col>
                                    </Row>
                                ))}
                            </Form>
                        </div>
                    )
                }}
            </Get>
            <div className={buttonContainer}>
                <Button
                    type="primary"
                    onClick={() => setVisible(true)}
                    ghost
                >
                    Шинэ
                </Button>
            </div>
            <Modal
                title="Нэмэлт талбар"
                onCancel={() => setVisible(false)}
                footer={null}
                visible={visible}
                closable={false}
            >
                <Post
                    url="api/custom-fields"
                    isReady={false}
                    data={{
                        name,
                        type,
                        organizationId: id
                    }}
                    onError={onError}
                    onSuccess={onSuccess}
                >
                    {(error, response, loading, makeRequest) => (
                        <Form onFinish={() => create(makeRequest)}>
                            <Form.Item
                                validateStatus={validateStatus('name')}
                                help={errors.name}
                            >
                                <Input
                                    size="large"
                                    placeholder="Нэр"
                                    value={name}
                                    onChange={e => setName(e.target.value)}
                                />
                            </Form.Item>
                            <Form.Item>
                                <Select
                                    value={type}
                                    onChange={setType}
                                >
                                    <Select.Option value="text">
                                        Текст
                                    </Select.Option>
                                    <Select.Option value="textarea">
                                        Урт текст
                                    </Select.Option>
                                    <Select.Option value="national-id">
                                        Регистрийн дугаар
                                    </Select.Option>
                                </Select>
                            </Form.Item>
                            <Button
                                loading={loading}
                                type="primary"
                                htmlType="submit"
                                ghost
                            >
                                Хадгалах
                            </Button>
                        </Form>
                    )}
                </Post>
            </Modal>
        </AppSettings>
    )
}

export default Admins