import * as React from 'react'
import { createUseStyles } from 'react-jss'
import {
    Col,
    Layout,
    Row
} from 'antd'
import {
    AppBar,
    AppSettingsMenu
} from './../components/app'

const useStyles = createUseStyles({
    app: {
        paddingTop: 64
    },
    content: {
        paddingTop: 50,
        paddingBottom: 50
    }
})

const App = props => {
    const {
        app,
        content
    } = useStyles()

    return (
        <div className={app}>
            <Layout>
                <AppBar />
                <Layout.Content className={content}>
                    <div className="container">
                        <Row gutter={24}>
                            <Col
                                xs={24}
                                sm={24}
                                md={8}
                            >
                                <AppSettingsMenu />
                            </Col>
                            <Col
                                xs={24}
                                sm={24}
                                md={16}
                            >
                                {props.children}
                            </Col>
                        </Row>
                    </div>
                </Layout.Content>
            </Layout>
        </div>
    )
}

export default App