import * as React from 'react'
import { createUseStyles } from 'react-jss'
import { Get } from 'react-axios'
import {
    Col,
    Empty,
    Layout,
    Row,
    Spin,
    Typography
} from 'antd'
import { App } from './../layouts'
import { Organization } from './../components'

const useStyles = createUseStyles({
    title: {
        marginBottom: '24px !important',
        textAlign: 'center'
    },
    spin: {
        textAlign: 'center'
    }
})

const SelectOrganization = props => {
    const {
        title,
        spin
    } = useStyles()

    const [
        data,
        setData
    ] = React.useState([])

    const onSuccess = ({ data }) => setData(data)

    return (
        <App>
            <Typography.Title
                level={4}
                className={title}
            >
                Байгууллага сонгох
            </Typography.Title>
            <Get
                url="api/admin/my/organizations"
                onSuccess={onSuccess}
                params={{
                    perPage: 0
                }}
            >
                {(error, response, loading) => {
                    if (loading) {
                        return (
                            <div className={spin}>
                                <Spin />
                            </div>
                        )
                    }

                    if (!data.length) {
                        return (
                            <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description="Танд удирдах боломжтой байгууллага байхгүй байна"
                            />
                        )
                    }

                    return (
                        <div className="container">
                            <Row
                                gutter={[16, 16]}
                                justify="center"
                            >
                                {data.map(({ id, name, category, logoPath }) => (
                                    <Col
                                        xs={24}
                                        sm={12}
                                    >
                                        <Organization
                                            id={id}
                                            name={name}
                                            categoryName={category.name}
                                            logoPath={logoPath}
                                        />
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    )
                }}
            </Get>
        </App>
    )
}

export default SelectOrganization