import * as React from 'react'
import {
    Divider,
    Typography
} from 'antd'
import { AppSettings } from './../layouts'
import {
    Employees,
    Services
} from './../pages'

const ServicesEmployees = props => {
    return (
        <AppSettings>
            <Typography.Title level={4}>
                Үйлчилгээ, ажилчин
            </Typography.Title>
            <Divider />
            <Services />
            <Divider />
            <Employees />
        </AppSettings>
    )
}

export default ServicesEmployees