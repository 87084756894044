import * as React from 'react'
import moment from 'moment'
import { createUseStyles } from 'react-jss'
import {
    useParams,
    generatePath
} from 'react-router-dom'
import {
    Get,
    Post
} from 'react-axios'
import {
    AutoComplete,
    Button,
    Col,
    DatePicker,
    Form,
    Input,
    message,
    Row,
    Select,
    Typography
} from 'antd'
import { App } from './../layouts'

const useStyles = createUseStyles({
    title: {
        marginBottom: 16
    },
    illustration: {
        display: 'block',
        width: '100%',
        maxWidth: 400,
        marginLeft: 'auto',
        marginRight: 'auto'
    }
})

const defaultService = {
    appointable: true
}

const defaultErrors = {
    surname: null,
    name: null,
    phone: null,
    serviceId: null,
    employeeId: null,
    date: null,
    form: null
}

const AppointmentsNew = props => {
    const { slug } = useParams()
    const {
        title,
        illustration
    } = useStyles()

    const [
        errors,
        setErrors
    ] = React.useState(defaultErrors)
    const [
        surname,
        setSurname
    ] = React.useState('')
    const [
        name,
        setName
    ] = React.useState('')
    const [
        countryCode
    ] = React.useState('+976')
    const [
        phone,
        setPhone
    ] = React.useState('')
    const [
        serviceId,
        setServiceId
    ] = React.useState(null)
    const [
        employeeId,
        setEmployeeId
    ] = React.useState(null)
    const [
        services,
        setServices
    ] = React.useState([])
    const [
        employees,
        setEmployees
    ] = React.useState([])
    const [
        date,
        setDate
    ] = React.useState(moment().format('YYYY-MM-DD'))
    const [
        from,
        setFrom
    ] = React.useState(null)
    const [
        availableTimes,
        setAvailableTimes
    ] = React.useState([])

    React.useEffect(() => {
        setEmployeeId(null)
    }, [
        serviceId
    ])

    React.useEffect(() => {
        setFrom(null)
    }, [
        serviceId,
        employeeId
    ])

    const isAppointable = value => (services.find(({ id }) => id === parseInt(value)) || defaultService).appointable

    const validateStatus = prop => {
        return errors[prop] === null
            ? ''
            : 'error'
    }

    const save = makeRequest => {
        setErrors(defaultErrors)
        makeRequest()
    }

    const onError = err => {
        if (err.response && err.response.status === 422) {
            setErrors({
                ...defaultErrors,
                ...err.response.data.errors
            })
        }
    }

    const onSuccess = () => {
        message.success('Шинэ захиалга амжилттай хадгаллаа')
        setSurname('')
        setName('')
        setPhone('')
        setServiceId(null)
        setDate(moment().format('YYYY-MM-DD'))
    }

    return (
        <App>
            <div className={title}>
                <Typography.Title level={4}>
                    Шинэ захиалга
                </Typography.Title>
            </div>
            <Row gutter={32}>
                <Col
                    xs={24}
                    md={12}
                    lg={8}
                >
                    <Post
                        url={generatePath('api/organizations/:id/appointments', { id: slug })}
                        isReady={false}
                        data={{
                            surname,
                            name,
                            countryCode,
                            phone,
                            from,
                            date,
                            serviceId,
                            employeeId
                        }}
                        onError={onError}
                        onSuccess={onSuccess}
                    >
                        {(error, response, loading, makeRequest) => (
                            <Form onFinish={() => save(makeRequest)}>
                                <Form.Item
                                    validateStatus={validateStatus('surname')}
                                    help={errors.surname}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Овог"
                                        value={surname}
                                        onChange={e => setSurname(e.target.value)}
                                    />
                                </Form.Item>
                                <Form.Item
                                    validateStatus={validateStatus('name')}
                                    help={errors.name}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Нэр"
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                    />
                                </Form.Item>
                                <Form.Item
                                    validateStatus={validateStatus('phone')}
                                    help={errors.phone}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Утасны дугаар"
                                        value={phone}
                                        onChange={e => setPhone(e.target.value)}
                                    />
                                </Form.Item>
                                <Form.Item
                                    validateStatus={validateStatus('date')}
                                    help={errors.date}
                                >
                                    <DatePicker
                                        placeholder="Огноо"
                                        size="large"
                                        allowClear={false}
                                        value={moment(date)}
                                        onChange={(time, timeString) => setDate(timeString)}
                                    />
                                </Form.Item>
                                <Get
                                    url={generatePath('api/organizations/:id/services', { id: slug })}
                                    onSuccess={({ data }) => setServices(data)}
                                    params={{
                                        perPage: 0,
                                        sortBy: 'name',
                                        sortOrder: 'asc'
                                    }}
                                >
                                    {(error, response, loading) => (
                                        <Form.Item
                                            validateStatus={validateStatus('serviceId')}
                                            help={errors.serviceId}
                                        >
                                            <Select
                                                placeholder="Үйлчилгээ"
                                                size="large"
                                                loading={loading}
                                                value={serviceId}
                                                onChange={setServiceId}
                                            >
                                                {services.map(({ id, name }) => (
                                                    <Select.Option key={id}>
                                                        {name}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    )}
                                </Get>
                                {serviceId && (
                                    isAppointable(serviceId)
                                        ?
                                        <Get
                                            url={generatePath('api/services/:serviceId/availability', { serviceId })}
                                            onSuccess={({ data }) => setAvailableTimes(data)}
                                            params={{
                                                date
                                            }}
                                        >
                                            {(error, response, loading) => (
                                                <Form.Item
                                                    validateStatus={validateStatus('from')}
                                                    help={errors.from}
                                                >
                                                    <AutoComplete
                                                        placeholder="Цаг"
                                                        size="large"
                                                        onChange={setFrom}
                                                    >
                                                        {availableTimes.map(time => (
                                                            <AutoComplete.Option key={time}>
                                                                {time}
                                                            </AutoComplete.Option>
                                                        ))}
                                                    </AutoComplete>
                                                </Form.Item>
                                            )}
                                        </Get>
                                        :
                                        <React.Fragment>
                                            <Get
                                                url={generatePath('api/services/:id/employees', { id: serviceId })}
                                                onSuccess={({ data }) => setEmployees(data)}
                                                params={{
                                                    perPage: 0,
                                                    sortBy: 'name',
                                                    sortOrder: 'asc'
                                                }}
                                            >
                                                {(error, response, loading) => (
                                                    <Form.Item
                                                        validateStatus={validateStatus('employeeId')}
                                                        help={errors.employeeId}
                                                    >
                                                        <Select
                                                            placeholder="Ажилчин"
                                                            size="large"
                                                            loading={loading}
                                                            value={employeeId}
                                                            onChange={setEmployeeId}
                                                        >
                                                            {employees.map(({ id, name }) => (
                                                                <Select.Option key={id}>
                                                                    {name}
                                                                </Select.Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                )}
                                            </Get>
                                            {employeeId && (
                                                <Get
                                                    url={generatePath('api/employees/:employeeId/:serviceId/availability', { employeeId, serviceId })}
                                                    onSuccess={({ data }) => setAvailableTimes(data)}
                                                    params={{
                                                        date
                                                    }}
                                                >
                                                    {(error, response, loading) => (
                                                        <Form.Item
                                                            validateStatus={validateStatus('from')}
                                                            help={errors.from}
                                                        >
                                                            <AutoComplete
                                                                placeholder="Цаг"
                                                                size="large"
                                                                onChange={setFrom}
                                                            >
                                                                {availableTimes.map(time => (
                                                                    <AutoComplete.Option key={time}>
                                                                        {time}
                                                                    </AutoComplete.Option>
                                                                ))}
                                                            </AutoComplete>
                                                        </Form.Item>
                                                    )}
                                                </Get>
                                            )}
                                        </React.Fragment>
                                )}
                                <Button
                                    disabled={!from}
                                    loading={loading}
                                    type="primary"
                                    htmlType="submit"
                                    ghost
                                >
                                    Хадгалах
                                </Button>
                            </Form>
                        )}
                    </Post>
                </Col>
                <Col
                    xs={0}
                    md={12}
                    lg={16}
                >
                    <img
                        className={illustration}
                        alt="booking"
                        src="/static/images/date-picker.svg"
                    />
                </Col>
            </Row>
        </App>
    )
}

export default AppointmentsNew