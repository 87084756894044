import * as React from 'react'
import { createUseStyles } from 'react-jss'
import { Area } from '@ant-design/charts'
import { Typography } from 'antd'

const useStyles = createUseStyles({
    tooltip: {
        paddingTop: 8,
        paddingBottom: 8
    }
})

const HomeChart = props => {
    const { tooltip } = useStyles()
    const {
        data,
        loading
    } = props

    return (
        <Area
            xField="key"
            yField="value"
            xAxis={false}
            color="#ea345c"
            data={data}
            tooltip={{
                customContent: (title) => (
                    <div className={tooltip}>
                        <Typography.Text>
                            {title} сар
                        </Typography.Text>
                    </div>
                )
            }}
            loading={loading}
            autoFit
            smooth
        />
    )
}

export default HomeChart