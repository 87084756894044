import * as React from 'react'
import { Input } from 'antd'

const CustomField = props => {
    const {
        type,
        ...other
    } = props

    switch (type) {
        case 'text':
            return (
                <Input {...other} />
            )
        case 'textarea':
            return (
                <Input.TextArea {...other} rows={4} />
            )
        case 'national-id':
            return (
                <Input {...other} />
            )
    }
}

export default CustomField