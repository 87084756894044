import * as React from 'react'
import moment from 'moment'
import { DeleteOutlined } from '@ant-design/icons'
import {
    Button,
    Col,
    Space,
    Row,
    Tag,
    Typography
} from 'antd'

const ScheduleDate = props => {
    const {
        date,
        intervals,
        onClickDelete
    } = props

    return (
        <div>
            <Row
                gutter={8}
                align="middle"
                justify="space-between"
            >
                <Col xs={10}>
                    <Typography.Text>
                        {date}
                    </Typography.Text>
                </Col>
                <Col xs={10}>
                    <Space direction="vertical">
                        {intervals.map(({ from, to }) => (
                            <Tag>
                                {moment(from, 'HH:mm:ss').format('HH:mm')} - {moment(to, 'HH:mm:ss').format('HH:mm')}
                            </Tag>
                        ))}
                    </Space>
                </Col>
                <Col>
                    <Button
                        onClick={onClickDelete}
                        shape="circle"
                        type="text"
                        icon={
                            <DeleteOutlined />
                        }
                    />
                </Col>
            </Row>
        </div>
    )
}

export default ScheduleDate