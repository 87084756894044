import * as React from 'react'
import moment from 'moment'
import {
    Button,
    Col,
    Space,
    Row,
    Tag,
    Typography
} from 'antd'

const Schedule = props => {
    const {
        day,
        intervals,
        onClickEdit
    } = props

    return (
        <div>
            <Row
                gutter={8}
                align="middle"
                justify="space-between"
            >
                <Col xs={6}>
                    <Typography.Text strong>
                        {day}
                    </Typography.Text>
                </Col>
                <Col flex="auto">
                    <Space direction="vertical">
                        {intervals.map(({ from, to }, index) => (
                            <Tag key={index}>
                                {moment(from, 'HH:mm:ss').format('HH:mm')} - {moment(to, 'HH:mm:ss').format('HH:mm')}
                            </Tag>
                        ))}
                    </Space>
                </Col>
                <Col>
                    <Button
                        onClick={onClickEdit}
                        type="primary"
                        ghost
                    >
                        Өөрчлөх
                    </Button>
                </Col>
            </Row>
        </div>
    )
}

export default Schedule