import * as React from 'react'
import {
    useParams,
    generatePath
} from 'react-router-dom'
import { createUseStyles } from 'react-jss'
import {
    Get,
    Put
} from 'react-axios'
import {
    Button,
    Col,
    Divider,
    Form,
    Input,
    message,
    Row,
    Typography
} from 'antd'
import { AppSettings } from './../layouts'
import { Spinner } from './../components/common'
import { Avatar } from './../components'
import { OrganizationSchedule } from './../components'

const useStyles = createUseStyles({
    title: {
        marginBottom: 16
    },
    scheduleContainer: {
        marginTop: 16
    }
})

const defaultErrors = {
    name: null,
    address: null,
    employeeName: null,
    logoPath: null
}

const Settings = props => {
    const { slug: id } = useParams()
    const {
        title,
        scheduleContainer
    } = useStyles()

    const [
        errors,
        setErrors
    ] = React.useState(defaultErrors)
    const [
        name,
        setName
    ] = React.useState('')
    const [
        address,
        setAddress
    ] = React.useState('')
    const [
        employeeName,
        setEmployeeName
    ] = React.useState('')
    const [
        logoPath,
        setLogoPath
    ] = React.useState('')
    const [
        scheduleType,
        setScheduleType
    ] = React.useState('')
    const [
        schedule,
        setSchedule
    ] = React.useState('')

    const setData = ({ data }) => {
        const {
            name,
            address,
            employeeName,
            logoPath,
            schedule,
            scheduleType
        } = data

        setName(name)
        setAddress(address)
        setEmployeeName(employeeName)
        setLogoPath(logoPath)
        setSchedule(schedule)
        setScheduleType(scheduleType)
    }

    const validateStatus = prop => {
        return errors[prop] === null
            ? ''
            : 'error'
    }

    const update = makeRequest => {
        setErrors(defaultErrors)
        makeRequest()
    }

    const onError = err => {
        if (err.response && err.response.status === 422) {
            setErrors({
                ...defaultErrors,
                ...err.response.data.errors
            })
        }
    }

    const onSuccess = data => {
        setData(data)
        message.success('Мэдээлэл амжилттай хадгаллаа')
    }

    return (
        <AppSettings>
            <Get
                url={generatePath('api/organizations/:id', { id })}
                onSuccess={setData}
            >
                {(error, response, loading) => (
                    <div>
                        <Spinner visible={loading} />
                        <Typography.Title level={4}>
                            Ерөнхий
                        </Typography.Title>
                        <Divider />
                        <Row gutter={16}>
                            <Col
                                xs={24}
                                lg={12}
                            >
                                <Put
                                    url={generatePath('api/organizations/:id', { id })}
                                    isReady={false}
                                    data={{
                                        name,
                                        address,
                                        employeeName,
                                        logoPath
                                    }}
                                    onError={onError}
                                    onSuccess={onSuccess}
                                >
                                    {(error, response, loading, makeRequest) => (
                                        <Form onFinish={() => update(makeRequest)}>
                                            <div className={title}>
                                                <Typography.Title level={5}>
                                                    Үндсэн мэдээлэл
                                                </Typography.Title>
                                            </div>
                                            <Form.Item>
                                                <Avatar
                                                    url="/api/upload/image"
                                                    src={`${process.env.REACT_APP_API_URL}/img/${logoPath}?w=200&h=200&fit=crop`}
                                                    size={96}
                                                    onSuccess={({ path }) => setLogoPath(path)}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                validateStatus={validateStatus('name')}
                                                help={errors.name}
                                            >
                                                <Input
                                                    size="large"
                                                    placeholder="Нэр"
                                                    value={name}
                                                    onChange={e => setName(e.target.value)}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                validateStatus={validateStatus('address')}
                                                help={errors.address}
                                            >
                                                <Input.TextArea
                                                    size="large"
                                                    placeholder="Хаяг"
                                                    rows={4}
                                                    value={address}
                                                    onChange={e => setAddress(e.target.value)}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                validateStatus={validateStatus('employeeName')}
                                                help={errors.employeeName}
                                            >
                                                <Input
                                                    size="large"
                                                    placeholder="Ажилчны нэршил (Үсчин, Эмч гэх мэт)"
                                                    value={employeeName}
                                                    onChange={e => setEmployeeName(e.target.value)}
                                                />
                                            </Form.Item>
                                            <Button
                                                loading={loading}
                                                type="primary"
                                                htmlType="submit"
                                                ghost
                                            >
                                                Хадгалах
                                            </Button>
                                        </Form>
                                    )}
                                </Put>
                            </Col>
                        </Row>
                        <Divider />
                        <div className={scheduleContainer}>
                            <Row gutter={16}>
                                <Col
                                    xs={24}
                                    lg={12}
                                >
                                    <OrganizationSchedule
                                        id={id}
                                        schedule={schedule}
                                        scheduleType={scheduleType}
                                        onChangeSchedule={setSchedule}
                                        onChangeScheduleType={setScheduleType}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </div>
                )}
            </Get>
        </AppSettings>
    )
}

export default Settings