import * as React from 'react'
import { createUseStyles } from 'react-jss'
import { Layout } from 'antd'
import { AppBar } from './../components/app'

const useStyles = createUseStyles({
    app: {
        paddingTop: 64
    },
    content: {
        paddingTop: 50,
        paddingBottom: 50
    }
})

const App = props => {
    const { fluid } = props
    const {
        app,
        content
    } = useStyles()

    return (
        <div className={app}>
            <Layout>
                <AppBar />
                <Layout.Content className={content}>
                    <div className={fluid ? 'container-fluid' : 'container'}>
                        {props.children}
                    </div>
                </Layout.Content>
            </Layout>
        </div >
    )
}

App.defaultProps = {
    fluid: false
}

export default App