import * as React from 'react'
import {
    useParams,
    generatePath
} from 'react-router-dom'
import {
    Get,
    Post
} from 'react-axios'
import {
    Button,
    Col,
    Divider,
    Form,
    Input,
    message,
    Row,
    Select,
    Typography
} from 'antd'
import { AppSettings } from './../layouts'
import { Avatar } from './../components'

const defaultErrors = {
    name: null,
    avatar: null,
    description: null,
    services: null
}

const EmployeesNew = props => {
    const { slug } = useParams()

    const [
        errors,
        setErrors
    ] = React.useState(defaultErrors)
    const [
        name,
        setName
    ] = React.useState('')
    const [
        avatar,
        setAvatar
    ] = React.useState('')
    const [
        description,
        setDescription
    ] = React.useState('')
    const [
        services,
        setServices
    ] = React.useState([])

    const validateStatus = prop => {
        return errors[prop] === null
            ? ''
            : 'error'
    }

    const save = makeRequest => {
        setErrors(defaultErrors)
        makeRequest()
    }

    const onError = err => {
        if (err.response && err.response.status === 422) {
            setErrors({
                ...defaultErrors,
                ...err.response.data.errors
            })
        }
    }

    const onSuccess = ({ data }) => {
        message.success('Ажилчин амжилттай хадгаллаа')
        setName('')
        setAvatar('')
        setDescription('')
        setServices([])
    }

    return (
        <AppSettings>
            <Typography.Title level={4}>
                Ажилчин
            </Typography.Title>
            <Divider />
            <Row gutter={16}>
                <Col
                    xs={24}
                    lg={12}
                >
                    <Post
                        url="api/employees"
                        isReady={false}
                        data={{
                            name,
                            avatar,
                            description,
                            services,
                            organizationId: slug
                        }}
                        onError={onError}
                        onSuccess={onSuccess}
                    >
                        {(error, response, loading, makeRequest) => (
                            <Form onFinish={() => save(makeRequest)}>
                                <Form.Item>
                                    {avatar
                                        ?
                                        <Avatar
                                            url="/api/upload/image"
                                            src={`${process.env.REACT_APP_API_URL}/img/${avatar}?&w=200&h=200&fit=crop`}
                                            size={80}
                                            onSuccess={({ path }) => setAvatar(path)}
                                        />
                                        :
                                        <Avatar
                                            url="/api/upload/image"
                                            size={80}
                                            onSuccess={({ path }) => setAvatar(path)}
                                        />
                                    }
                                </Form.Item>
                                <Form.Item
                                    validateStatus={validateStatus('name')}
                                    help={errors.name}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Нэр"
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                    />
                                </Form.Item>
                                <Form.Item
                                    validateStatus={validateStatus('description')}
                                    help={errors.description}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Тайлбар"
                                        value={description}
                                        onChange={e => setDescription(e.target.value)}
                                    />
                                </Form.Item>
                                <Get
                                    url={generatePath('api/organizations/:id/services', { id: slug })}
                                    params={{
                                        perPage: 0,
                                        sortBy: 'name',
                                        sortOrder: 'asc'
                                    }}
                                >
                                    {(error, response, loading) => (
                                        <Form.Item
                                            validateStatus={validateStatus('services')}
                                            help={errors.services}
                                        >
                                            <Select
                                                size="large"
                                                mode="multiple"
                                                placeholder="Үйлчилгээ"
                                                loading={loading}
                                                value={services}
                                                onChange={setServices}
                                                allowClear
                                            >
                                                {response && response.data.map(({ id, name }) => (
                                                    <Select.Option key={id}>
                                                        {name}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    )}
                                </Get>
                                <Button
                                    loading={loading}
                                    type="primary"
                                    htmlType="submit"
                                    ghost
                                >
                                    Хадгалах
                                </Button>
                            </Form>
                        )}
                    </Post>
                </Col>
            </Row>
        </AppSettings>
    )
}

export default EmployeesNew