import * as React from 'react'
import { UserOutlined } from '@ant-design/icons'
import { ListItem } from './../components/common'

const AdminInvitation = ({ email }) => (
    <ListItem
        title={email}
        icon={
            <UserOutlined />
        }
    />
)

export default AdminInvitation