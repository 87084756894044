import * as React from 'react'
import moment from 'moment'
import { generatePath } from 'react-router-dom'
import { createUseStyles } from 'react-jss'
import { Post } from 'react-axios'
import {
    Button,
    Form,
    message,
    Radio,
    TimePicker,
    Typography
} from 'antd'

const useStyles = createUseStyles({
    title: {
        //
    },
    subtitle: {
        marginTop: 2,
        marginBottom: 16
    },
    group: {
        marginBottom: 24
    }
})

const options = [
    {
        label: 'Цагийн хуваарь байхгүй',
        value: 'no-schedule'
    },
    {
        label: 'Үргэлж нээлттэй',
        value: 'always-open'
    },
    {
        label: 'Өдөр, өдрөөр цагийн хуваарь тохируулах',
        value: 'standard'
    }
]

const OrganizationSchedule = props => {
    const {
        title,
        subtitle,
        group
    } = useStyles()

    const {
        id,
        schedule,
        scheduleType,
        onChangeSchedule,
        onChangeScheduleType
    } = props

    const getSchedule = day => {
        if (
            !schedule[day] ||
            !schedule[day].from ||
            !schedule[day].to
        ) {
            return [
                null,
                null
            ]
        }

        return [
            moment(schedule[day].from, 'HH:mm:ss'),
            moment(schedule[day].to, 'HH:mm:ss')
        ]
    }

    const onChange = (day, time) => {
        const [
            from,
            to
        ] = time

        onChangeSchedule({
            ...schedule,
            [day]: {
                from,
                to
            }
        })
    }

    const onError = err => {
        //
    }

    const onSuccess = () => message.success('Мэдээлэл амжилттай хадгаллаа')

    return (
        <div>
            <div className={title}>
                <Typography.Title level={5}>
                    Цагийн хуваарь
                </Typography.Title>
            </div>
            <div className={subtitle}>
                <Typography.Text type="secondary">
                    Доорх мэдээлэл нь захиалах боломжтой цагийн мэдээлэл биш болно
                </Typography.Text>
            </div>
            <Post
                url={generatePath('api/organizations/:id/schedules', { id })}
                isReady={false}
                data={{
                    ...schedule,
                    scheduleType
                }}
                onError={onError}
                onSuccess={onSuccess}
            >
                {(error, response, loading, makeRequest) => (
                    <Form
                        layout="vertical"
                        onFinish={() => makeRequest()}
                    >
                        <div className={group}>
                            <Radio.Group
                                options={options}
                                onChange={e => onChangeScheduleType(e.target.value)}
                                value={scheduleType}
                            />
                        </div>
                        {scheduleType === 'standard' &&
                            <div>
                                <Form.Item label="Даваа">
                                    <TimePicker.RangePicker
                                        value={getSchedule('monday')}
                                        onChange={(time, timeString) => onChange('monday', timeString)}
                                        format="HH:mm"
                                        size="large"
                                        placeholder={[
                                            'Нээх', 'Хаах'
                                        ]}
                                        allowClear
                                    />
                                </Form.Item>
                                <Form.Item label="Мягмар">
                                    <TimePicker.RangePicker
                                        value={getSchedule('tuesday')}
                                        onChange={(time, timeString) => onChange('tuesday', timeString)}
                                        format="HH:mm"
                                        size="large"
                                        placeholder={[
                                            'Нээх', 'Хаах'
                                        ]}
                                        allowClear
                                    />
                                </Form.Item>
                                <Form.Item label="Лхагва">
                                    <TimePicker.RangePicker
                                        value={getSchedule('wednesday')}
                                        onChange={(time, timeString) => onChange('wednesday', timeString)}
                                        format="HH:mm"
                                        size="large"
                                        placeholder={[
                                            'Нээх', 'Хаах'
                                        ]}
                                        allowClear
                                    />
                                </Form.Item>
                                <Form.Item label="Пүрэв">
                                    <TimePicker.RangePicker
                                        value={getSchedule('thursday')}
                                        onChange={(time, timeString) => onChange('thursday', timeString)}
                                        format="HH:mm"
                                        size="large"
                                        placeholder={[
                                            'Нээх', 'Хаах'
                                        ]}
                                        allowClear
                                    />
                                </Form.Item>
                                <Form.Item label="Баасан">
                                    <TimePicker.RangePicker
                                        value={getSchedule('friday')}
                                        onChange={(time, timeString) => onChange('friday', timeString)}
                                        format="HH:mm"
                                        size="large"
                                        placeholder={[
                                            'Нээх', 'Хаах'
                                        ]}
                                        allowClear
                                    />
                                </Form.Item>
                                <Form.Item label="Бямба">
                                    <TimePicker.RangePicker
                                        value={getSchedule('saturday')}
                                        onChange={(time, timeString) => onChange('saturday', timeString)}
                                        format="HH:mm"
                                        size="large"
                                        placeholder={[
                                            'Нээх', 'Хаах'
                                        ]}
                                        allowClear
                                    />
                                </Form.Item>
                                <Form.Item label="Ням">
                                    <TimePicker.RangePicker
                                        value={getSchedule('sunday')}
                                        onChange={(time, timeString) => onChange('sunday', timeString)}
                                        format="HH:mm"
                                        size="large"
                                        placeholder={[
                                            'Нээх', 'Хаах'
                                        ]}
                                        allowClear
                                    />
                                </Form.Item>
                            </div>
                        }
                        <Button
                            loading={loading}
                            type="primary"
                            htmlType="submit"
                            ghost
                        >
                            Хадгалах
                        </Button>
                    </Form>
                )}
            </Post>
        </div>
    )
}

export default OrganizationSchedule