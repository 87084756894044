import * as React from 'react'
import {
    useRouteMatch,
    Link
} from 'react-router-dom'
import { createUseStyles } from 'react-jss'
import {
    Button,
    Layout,
    Menu,
    Space
} from 'antd'
import {
    NotificationsMenu,
    UserMenu
} from './../app'

const useStyles = createUseStyles({
    header: {
        position: 'fixed',
        top: 0,
        zIndex: 999,
        width: '100%',
        padding: 0
    },
    headerItems: {
        height: 64,
        display: 'flex',
        alignItems: 'center'
    },
    logo: {
        display: 'block',
        marginRight: 24
    },
    orderButton: {
        margin: '0 24px'
    }
})

const AppBar = props => {
    const {
        header,
        headerItems,
        logo,
        orderButton
    } = useStyles()

    const {
        url,
        params
    } = useRouteMatch()

    const { slug } = params

    return (
        <div>
            <Layout.Header className={header}>
                <div className="container">
                    <div className={headerItems}>
                        <Link to="/">
                            <img
                                className={logo}
                                height="30"
                                alt="toblo"
                                src="/static/images/logo-symbol.png"
                            />
                        </Link>
                        {!!slug &&
                            <React.Fragment>
                                <Menu
                                    mode="horizontal"
                                    theme="dark"
                                    selectedKeys={[]}
                                >
                                    <Menu.Item>
                                        <Link to={`/org/${slug}`}>
                                            Нүүр
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item>
                                        <Link to={`/org/${slug}/calendar`}>
                                            Календарь
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item>
                                        <Link to={`/org/${slug}/appointments`}>
                                            Захиалга
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item>
                                        <Link to={`/org/${slug}/settings`}>
                                            Тохиргоо
                                        </Link>
                                    </Menu.Item>
                                </Menu>
                                <Link
                                    className={orderButton}
                                    component={props => <Button {...props} ghost />}
                                    to={`/org/${slug}/appointments/create`}
                                >
                                    Шинэ захиалга
                                </Link>
                            </React.Fragment>
                        }
                        <div style={{ flex: 1 }}></div>
                        <Space size="middle">
                            <NotificationsMenu />
                            <UserMenu />
                        </Space>
                    </div>
                </div>
            </Layout.Header>
        </div>
    )
}

export default AppBar