import * as React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { connect } from 'react-redux'
import { createUseStyles } from 'react-jss'
import { Get } from 'react-axios'
import { BellOutlined } from '@ant-design/icons'
import {
    Badge,
    Button,
    Divider,
    Empty,
    Popover,
    Spin,
    Typography
} from 'antd'
import { Notification } from './../../components'
import Echo from './../../plugins/laravel-echo'

const useStyles = createUseStyles({
    card: {
        minWidth: 360
    },
    cardBody: {
        padding: '12px 24px'
    },
    button: {
        color: '#fff'
    },
    spin: {
        textAlign: 'center',
        paddingTop: 24,
        paddingBottom: 24
    },
    divider: {
        marginTop: 0,
        marginBottom: 0
    },
    scrollable: {
        maxHeight: 400,
        overflow: 'auto'
    }
})

const perPage = 10

const NotificationsMenu = ({ user }) => {
    const echo = Echo()
    const {
        card,
        cardBody,
        button,
        spin,
        divider,
        scrollable
    } = useStyles()

    const [
        count,
        setCount
    ] = React.useState(0)
    const [
        page,
        setPage
    ] = React.useState(1)
    const [
        total,
        setTotal
    ] = React.useState(0)
    const [
        notifications,
        setNotifications
    ] = React.useState([])

    React.useEffect(() => {
        listen()
    }, [])

    const listen = () => {
        echo.private(`App.Models.Admin.${user.id}`).notification(data => {
            setCount(count + 1)
            setNotifications([
                data,
                ...notifications
            ])
        })
    }

    const setData = ({ data, currentPage, total }) => {
        setPage(currentPage)
        setTotal(total)
        setNotifications([
            ...notifications,
            ...data
        ])
    }

    const menu = (
        <div className={card}>
            <div className={cardBody}>
                <Typography.Title level={5}>
                    Мэдэгдэл
                </Typography.Title>
            </div>
            <Divider className={divider} />
            <Get
                url="api/admin/notifications"
                onSuccess={setData}
                params={{
                    page,
                    perPage
                }}
            >
                {(error, response, loading) => (
                    <div>
                        <div
                            id="scrollable"
                            className={scrollable}
                        >
                            <InfiniteScroll
                                dataLength={notifications.length}
                                next={() => setPage(page + 1)}
                                hasMore={total > page * perPage}
                                scrollableTarget="scrollable"
                            >
                                {notifications.map(({ id, type, data, readAt, created_at }) => (
                                    <div key={id}>
                                        <Notification
                                            type={type}
                                            data={data}
                                            readAt={readAt}
                                            createdAt={created_at}
                                        />
                                        <Divider className={divider} />
                                    </div>
                                ))}
                                {loading &&
                                    <div className={spin}>
                                        <Spin />
                                    </div>
                                }
                                {!loading && !notifications.length &&
                                    <div className={cardBody}>
                                        <Empty
                                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                                            description="Мэдэгдэл байхгүй байна"
                                        />
                                    </div>
                                }
                            </InfiniteScroll>
                        </div>
                    </div>
                )}
            </Get>
        </div>
    )

    return (
        <Get
            url="api/admin/unread/notifications/count"
            onSuccess={({ count }) => setCount(count)}
        >
            {() => (
                <Popover
                    placement="bottomRight"
                    content={menu}
                    trigger="click"
                >
                    <Badge count={count}>
                        <Button
                            className={button}
                            type="link"
                            shape="circle"
                            onClick={() => setCount(0)}
                            icon={
                                <BellOutlined />
                            }
                        />
                    </Badge>
                </Popover>
            )}
        </Get>
    )
}

const mapStateToProps = state => ({
    user: state.auth.user
})

export default connect(
    mapStateToProps,
    null
)(NotificationsMenu)