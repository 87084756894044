import * as React from 'react'
import classNames from 'classnames'
import { generatePath } from 'react-router'
import { createUseStyles } from 'react-jss'
import { Link } from 'react-router-dom'
import {
    Avatar,
    Card,
    Typography
} from 'antd'

const useStyles = createUseStyles({
    card: {
        textAlign: 'center'
    },
    description: {
        marginTop: 16
    }
})

const Organization = props => {
    const {
        card,
        description
    } = useStyles()

    const {
        className,
        id,
        name,
        categoryName,
        logoPath
    } = props

    return (
        <Card
            className={classNames(card, className)}
            actions={[
                <Link
                    to={generatePath('/org/:id', { id })}
                    component={Typography.Link}
                >
                    Нэвтрэх
                </Link>
            ]}
        >
            <Avatar
                size={48}
                src={`${process.env.REACT_APP_API_URL}/img/${logoPath}?w=64&h=64&fit=crop`}
            />
            <div className={description}>
                <Typography.Text type="secondary">
                    {categoryName}
                </Typography.Text>
            </div>
            <Typography.Title level={5}>
                {name}
            </Typography.Title>
        </Card>
    )
}

export default Organization