import * as React from 'react'
import {
    Link,
    useParams,
    generatePath
} from 'react-router-dom'
import { withRouter } from 'react-router'
import { createUseStyles } from 'react-jss'
import { Get } from 'react-axios'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import {
    Button,
    Col,
    Empty,
    Modal,
    Row,
    Spin,
    Typography
} from 'antd'
import { Service } from './../components'
import { API } from './../plugins/axios'

const useStyles = createUseStyles({
    spin: {
        textAlign: 'center'
    }
})

const Services = props => {
    const { slug } = useParams()
    const {
        spin
    } = useStyles()

    const [
        services,
        setServices
    ] = React.useState([])

    const destroy = id => {
        Modal.confirm({
            title: 'Устгах',
            icon: <ExclamationCircleOutlined />,
            content: 'Та энэ үйлчилгээг устгахыг зөвшөөрч байна уу?',
            okText: 'Тийм',
            cancelText: 'Үгүй',
            onOk: async () => {
                try {
                    await API.delete(generatePath('api/services/:id', { id }))
                    setServices(services.filter(service => service.id !== id))
                } catch (err) {
                    //
                }
            }
        })
    }

    return (
        <div>
            <Row
                gutter={16}
                align="middle"
                justify="space-between"
                style={{
                    marginBottom: 16
                }}
            >
                <Col>
                    <Typography.Title level={5}>
                        Үйлчилгээнүүд
                    </Typography.Title>
                </Col>
                <Col>
                    <Link
                        type="primary"
                        to={generatePath('/org/:slug/settings/services/create', { slug })}
                        component={Button}
                        ghost
                    >
                        Шинэ
                    </Link>
                </Col>
            </Row>
            <Get
                url={generatePath('api/organizations/:id/services', { id: slug })}
                onSuccess={({ data }) => setServices(data)}
                params={{
                    perPage: 0,
                    sortBy: 'name',
                    sortOrder: 'asc'
                }}
            >
                {(error, response, loading) => {
                    if (loading) {
                        return (
                            <div className={spin}>
                                <Spin />
                            </div>
                        )
                    }

                    if (!services.length) {
                        return (
                            <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description="Үйлчилгээ бүртгээгүй байна"
                            />
                        )
                    }

                    return (
                        <div>
                            <Row gutter={[16, 16]}>
                                {services.map(({ id, name, duration, price, notes }) => (
                                    <Col
                                        key={id}
                                        xs={12}
                                    >
                                        <Service
                                            name={name}
                                            duration={duration}
                                            price={price}
                                            notes={notes}
                                            onClickCalendar={() => props.history.push(generatePath('/org/:slug/settings/services/:id/schedule', {
                                                slug,
                                                id
                                            }))}
                                            onClickEdit={() => props.history.push(generatePath('/org/:slug/settings/services/:id', {
                                                slug,
                                                id
                                            }))}
                                            onClickDelete={() => destroy(id)}
                                        />
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    )
                }}
            </Get>
        </div>
    )
}

export default withRouter(Services)