import * as React from 'react'
import { UserOutlined } from '@ant-design/icons'
import { ListItem } from './../components/common'

const Admin = props => {
    const {
        name,
        email,
        onClickDelete
    } = props

    return (
        <ListItem
            title={name}
            description={email}
            actionText="Устгах"
            onClickAction={onClickDelete}
            icon={
                <UserOutlined />
            }
        />
    )
}

export default Admin