import * as React from 'react'
import {
    BrowserRouter as Router,
    Route,
    Switch
} from 'react-router-dom'
import {
    GuestRoute,
    UserRoute
} from './components/common'
import {
    Admins,
    Appointment,
    Appointments,
    AppointmentsNew,
    Calendar,
    ChangeEmailAddress,
    ChangeEmailAddressVerify,
    ChangeName,
    ChangePassword,
    CustomFields,
    EmployeesNew,
    EmployeesUpdate,
    ForgotPassword,
    ForgotPasswordReset,
    Home,
    Login,
    NoMatch,
    Register,
    SelectOrganization,
    ServicesEmployees,
    ServicesNew,
    ServicesSetSchedule,
    ServicesUpdate,
    Settings,
    User
} from './pages'

const guestRoutes = [
    {
        path: '/login',
        children: <Login />
    },
    {
        path: '/register',
        children: <Register />
    },
    {
        path: '/forgot/password/reset',
        children: <ForgotPasswordReset />
    },
    {
        path: '/forgot/password',
        children: <ForgotPassword />
    }
]

const userRoutes = [
    {
        path: '/',
        children: <SelectOrganization />
    },
    {
        path: '/user',
        children: <User />
    },
    {
        path: '/change/email/request',
        children: <ChangeEmailAddress />
    },
    {
        path: '/change/email',
        children: <ChangeEmailAddressVerify />
    },
    {
        path: '/change/name',
        children: <ChangeName />
    },
    {
        path: '/change/password',
        children: <ChangePassword />
    },
    {
        path: '/org/:slug',
        children: <Home />
    },
    {
        path: '/org/:slug/appointments',
        children: <Appointments />
    },
    {
        path: '/org/:slug/appointments/create',
        children: <AppointmentsNew />
    },
    {
        path: '/org/:slug/appointments/:id',
        children: <Appointment />
    },
    {
        path: '/org/:slug/calendar',
        children: <Calendar />
    },
    {
        path: '/org/:slug/settings',
        children: <Settings />
    },
    {
        path: '/org/:slug/settings/services-employees',
        children: <ServicesEmployees />
    },
    {
        path: '/org/:slug/settings/services/create',
        children: <ServicesNew />
    },
    {
        path: '/org/:slug/settings/services/:id/schedule',
        children: <ServicesSetSchedule />
    },
    {
        path: '/org/:slug/settings/services/:id',
        children: <ServicesUpdate />
    },
    {
        path: '/org/:slug/settings/employees/create',
        children: <EmployeesNew />
    },
    {
        path: '/org/:slug/settings/employees/:id',
        children: <EmployeesUpdate />
    },
    {
        path: '/org/:slug/settings/admins',
        children: <Admins />
    },
    {
        path: '/org/:slug/settings/custom-fields',
        children: <CustomFields />
    }
]

export default () => (
    <Router>
        <Switch>
            {guestRoutes.map((props, index) => (
                <GuestRoute
                    {...props}
                    key={index}
                    exact
                />
            ))}
            {userRoutes.map((props, index) => (
                <UserRoute
                    {...props}
                    key={index}
                    exact
                />
            ))}
            <Route path="*">
                <NoMatch />
            </Route>
        </Switch>
    </Router>
)